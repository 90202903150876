import React, { useEffect, useReducer, useState } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { checkBoxCell, customCell, dateCell, GridPanel, IRoutedTabProps, YesNoSwitch } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialSettingState } from "../../../state";
import { settingReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Permission } from "../../../utils/enums";
import { IGroup, IUser } from "../../../utils/types/models";
import GroupEditor from "../../editors/project/group";
import { colorCellClassName, occupationColorCell } from "../../global/colorCell";

import style from "../../../assets/masterDetail.module.scss";
import GroupParticipantsDetail from "./groupParticipantsDetail";
import GroupParticipantsDetailSessions from "./groupParticipantsDetailSessions";

const Groups: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [groupId, setGroupId] = useState(0);
	const [participantId, setParticipantId] = useState(0);

	const [settingOrangeState, settingOrangeDispatch] = useReducer(settingReducer, initialSettingState);
	const [settingRedState, settingRedDispatch] = useReducer(settingReducer, initialSettingState);
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		apiService.callApi(settingOrangeDispatch, Endpoint.SettingsByKey, "GET", { key: "GroupOccupationOrange" });
		apiService.callApi(settingRedDispatch, Endpoint.SettingsByKey, "GET", { key: "GroupOccupationRed" });
	}, [apiService]);

	useEffect(() => {
		setParticipantId(0);
	}, [groupId]);

	return (
		<div className="d-flex flex-column" style={{ overflowX: "auto" }}>
			<GridPanel
				listEndpoint={showAll ? Endpoint.GeneralGroupsList : Endpoint.MyGroupsList}
				endpoint={Endpoint.Groups}
				className={style.masterGrid}
				editScreen={{
					screen: GroupEditor,
					isAllowed: (group: IGroup) => hasPermission(Permission.GroupsUpdate) && (hasPermission(Permission.GroupsAdministrator) || group.responsibleUserId === currentUser.id),
					extraProps: (group: IGroup) => ({ projectId: group.projectId })
				}}
				delete={false}
				extraToolbarButtons={
					hasPermission(Permission.GroupsAll) && [
						<span key="showAllLabel" style={{ marginRight: "10px" }}>
							{t("showAllGroups")}
						</span>,
						<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
					]
				}
				onSelectionChange={(entity: IGroup) => {
					if (entity) {
						setGroupId(entity.id);
					}
				}}
				filter={{
					logic: "and",
					filters: [
						{
							field: "active",
							operator: "eq",
							value: true
						}
					]
				}}
			>
				<GridColumn field="name" title={t("name")} />
				<GridColumn field="project.title" title={t("project")} />
				<GridColumn field="firstSessionDate" title={t("firstSessionDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
				<GridColumn field="responsibleUser.fullName" title={t("groupResponsible")} width={currentUser.isReplacingUsers.length <= 0 ? "300px" : undefined} />
				<GridColumn
					field="occupationPercentage"
					title={t("occupation")}
					filter="numeric"
					width="200px"
					cell={customCell(occupationColorCell(true, settingOrangeState.setting?.value, settingRedState.setting?.value), colorCellClassName)}
				/>
				<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
			</GridPanel>
			<div className="row">
				{groupId !== 0 && (
					<div className="col">
						<div className={style.detailGrid + " "}>
							<div>
								<GroupParticipantsDetail groupId={groupId} setGroupId={setGroupId}  setParticipant={setParticipantId} />
							</div>
						</div>
					</div>
				)}
				{participantId !== 0 && (
					<div className="col">
						<div className={style.detailGrid + " "}>
							<div>
								<GroupParticipantsDetailSessions groupId={groupId} participantId={participantId} setParticipantId={setParticipantId} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Groups;
