import React, { forwardRef, Ref, useState } from "react";

import { ButtonGroup } from "@progress/kendo-react-buttons";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { StandardButton, WeekPicker } from "@selas/ui-components";
import { WeekPickerChangeEvent } from "@selas/ui-components/dist/editor/weekPicker";
import { getWeekRange } from "@selas/utils";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { IPlanningChild } from ".";
import { ISession, IUser } from "../../../../utils/types/models";
import OutlookPlanning from "./outlookPlanning";
import PlanningBoard from "./planningBoard";
import UserFilter from "./userFilter";

import style from "./planning.module.scss";
import { IApplicationState } from "../../../../store";
import { useSelector } from "react-redux";

interface Props {
	redLimit: string;
	orangeLimit: string;
	openSession: (session: ISession, readOnly: boolean) => void;
	showLoader: (key: string) => void;
	hideLoader: (key: string) => void;
}

function Weekplanning({ redLimit, orangeLimit, openSession, showLoader, hideLoader }: Props, ref: Ref<IPlanningChild>): JSX.Element {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [view, setView] = useState<"sessions" | "outlook">("sessions");

	const defaultWeek: SelectionRange = getWeekRange(new Date(), intl.firstDay());
	const [selectedWeek, setSelectedWeek] = useState<SelectionRange>(defaultWeek);

	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>([currentUser]);

	function onClickWeek(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		if (event.currentTarget.id === "buttonPrev") {
			setSelectedWeek(getWeekRange(dayjs(selectedWeek.start).add(-7, "day").toDate(), intl.firstDay()));
		} else if (event.currentTarget.id === "buttonCurrent") {
			setSelectedWeek(defaultWeek);
		} else if (event.currentTarget.id === "buttonNext") {
			setSelectedWeek(getWeekRange(dayjs(selectedWeek.start).add(7, "day").toDate(), intl.firstDay()));
		}
	}

	return (
		<div className="col" style={{ maxHeight: "83%" }}>
			<div className={"row " + style.planningNavbar}>
				<div className="col-2">
					<div className={style.view}>{t("view")}</div>
					<ButtonGroup>
						<StandardButton togglable selected={view === "sessions"} onClick={() => setView("sessions")}>
							{t("sessions")}
						</StandardButton>
						<StandardButton togglable selected={view === "outlook"} onClick={() => setView("outlook")}>
							Outlook
						</StandardButton>
					</ButtonGroup>
				</div>
				<div className="col">
					<div className="d-flex justify-content-center" style={{ marginTop: "20px", marginBottom: "25px" }}>
						<div style={{ marginRight: "40px" }}>
							<WeekPicker name="weekSelector" value={selectedWeek.end} onChange={(event: WeekPickerChangeEvent) => setSelectedWeek(event.value)} />
						</div>
						<StandardButton id="buttonPrev" onClick={onClickWeek}>
							{t("planning.prevWeek")}
						</StandardButton>
						<StandardButton id="buttonCurrent" primary onClick={onClickWeek}>
							{t("planning.currentWeek")}
						</StandardButton>
						<StandardButton id="buttonNext" onClick={onClickWeek}>
							{t("planning.nextWeek")}
						</StandardButton>
						<div className="flex-grow-1" />
					</div>
				</div>
				<div className="col">
					<span style={{ padding: "5px" }} className={style.userName}>
						{t("displayedUsers")}
					</span>
					<UserFilter setSelectedUsers={setSelectedUsers} />
				</div>
			</div>
			{view === "sessions" && (
				<PlanningBoard
					ref={ref}
					users={selectedUsers}
					week={selectedWeek}
					orangeLimit={orangeLimit}
					redLimit={redLimit}
					openSession={openSession}
					showLoader={showLoader}
					hideLoader={hideLoader}
				/>
			)}
			{view === "outlook" && <OutlookPlanning ref={ref} users={selectedUsers} week={selectedWeek} showLoader={showLoader} hideLoader={hideLoader} />}
		</div>
	);
}

export default forwardRef(Weekplanning);
