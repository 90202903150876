import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import SessionTemplateEditor from "../../editors/catalogue/sessionTemplate";

const SessionTemplates: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.SessionTemplatesList}
			endpoint={Endpoint.SessionTemplates}
			addScreen={{ screen: SessionTemplateEditor, isAllowed: hasPermission(Permission.SessionTemplatesAdd) }}
			editScreen={{ screen: SessionTemplateEditor, isAllowed: hasPermission(Permission.SessionTemplatesUpdate) }}
			delete={hasPermission(Permission.SessionTemplatesDelete)}
			filter={{
				logic: "and",
				filters: [{ field: "active", operator: "eq", value: true }]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="isCollective" title={t("isCollective")} filter="boolean" cell={customCell(checkBoxCell())} />
			<GridColumn field="isOnSite" title={t("isOnSite")} filter="boolean" cell={customCell(checkBoxCell())} />
			<GridColumn field="maximumParticipantCount" title={t("maximumParticipantCount")} filter="numeric" />
			<GridColumn field="sendSurveyOnClose" title={t("sendSurveyOnClose")} filter="boolean" cell={customCell(checkBoxCell())} />
			<GridColumn field="active" title={t("active")} filter="boolean" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default SessionTemplates;
