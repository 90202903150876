import React, { Dispatch, ReactElement, useCallback, useEffect, useReducer, useRef, useState } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, ListItemProps, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { derender, getInitialState, render } from "@selas/state-management";
import {
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	ManageableMultiSelect,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel,
	YesNoSwitch
} from "@selas/ui-components";
import { getDate, isNullOrEmpty, newKey } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import isNull from "lodash/isNull";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialUserState } from "../../../../state";
import {
	companyReducer,
	contractTypeReducer,
	jobFunctionReducer,
	jointCommitteeReducer,
	nacebelCodeReducer,
	participantReducer,
	projectParticipantReducer,
	sectorReducer,
	userReducer
} from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { AgeClass, CommunicationLanguage, Permission, ProjectParticipantStatus, ProjectParticipantStatute } from "../../../../utils/enums";
import { IProjectParticipantProps } from "../../../../utils/props";
import {
	ICompany,
	IContractType,
	IIntake,
	IJobFunction,
	IJointCommittee,
	INacebelCode,
	IParticipant,
	IProjectParticipant,
	IProjectParticipantJointCommittee,
	IProjectParticipantNacebelCode,
	IProjectParticipantSector,
	ISector,
	IUser
} from "../../../../utils/types/models";
import { statusDropdownItemRender } from "../../../global/statusDropdownItemRender";
import { getProjectParticipantStatusColor } from "../../../pages/work/participants";
import SectorEditor from "../../catalogue/sector";
import CompanyEditor from "../../customer/company";
import ParticipantEditor from "../../customer/participant";
import ParticipantEditorContent from "../../customer/participant/editorContent";
import DatePickerForDateOnly from "../../datepicker/datePicker";
import JointCommitteeEditor from "../../masterdata/jointCommittee";
import NacebelCodeEditor from "../../masterdata/nacebelCode";
import ContractTypeEditor from "../../personInfo/contractType";
import JobFunctionEditor from "../../personInfo/jobFunction";
import RequestMail from "../../simpleEmailSend/requestMail";
import IntakeEditor from "../intake";
import ParticipantStatusEditor from "../projectParticipantStatusChangeForm/participantStatusChangeForm";
import ProjectParticipantAttachments from "./grids/projectParticipantAttachments";
import ProjectParticipantEmails from "./grids/projectParticipantEmails";
import ProjectParticipantFollowUpTasks from "./grids/projectParticipantFollowUpTasks";
import ProjectParticipantNotes from "./grids/projectParticipantNotes";
import ProjectParticipantServices from "./grids/projectParticipantServices";

interface IProps {
	projectId: number;
	statusChange: boolean;
	companyId?: number;
}

const ProjectParticipantEditor: React.FC<(IAddEntityScreenProps<IProjectParticipant> | IEditEntityScreenProps<IProjectParticipant>) & IProps> = (
	props: (IAddEntityScreenProps<IProjectParticipant> | IEditEntityScreenProps<IProjectParticipant>) & IProps
) => {
	const { t } = useTranslation();

	const [nacebelCodeState, nacebelCodeDispatch] = useReducer(nacebelCodeReducer, getInitialState<INacebelCode>());
	const [jointCommitteeState, jointCommitteeDispatch] = useReducer(jointCommitteeReducer, getInitialState<IJointCommittee>());

	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ReduxDispatch = useDispatch();

	const emptyParticipant: IParticipant = {
		id: 0,
		nationalRegistryNumber: "",
		firstName: "",
		lastName: "",
		fullName: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		phoneNumber: "",
		mobilePhoneNumber: "",
		emailAddress: "",
		nationalityId: null,
		dateOfBirth: null,
		knownLanguages: [],
		highestDegreeId: null,
		communicationLanguage: CommunicationLanguage.Dutch,
		active: true,
		isInfoAndRefferalEmailAllowed: true
	};

	const initialProjectParticipant: IProjectParticipant = {
		id: (props as IEditEntityScreenProps<IProjectParticipant>).recordId || 0,
		participantId: null,
		participant: emptyParticipant,
		projectId: props.projectId,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		sectors: [],
		putNameOnInvoice: false,
		contractSent: false,
		contractSignedReceived: false,
		contractSentToGovernment: false,
		outplacementCertificate: false,
		reclamation: false,
		status: ProjectParticipantStatus.New,
		statute: null,
		projectServices: [],
		notes: [],
		emails: [],
		attachments: [],
		incommingJointCommittees: [],
		incommingNacebelCodes: [],
		outgoingJointCommittees: [],
		outgoingNacebelCodes: [],
		accessToTraP: false
	};

	const [projectParticipant, setProjectParticipant] = useState<IProjectParticipant>(initialProjectParticipant);
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [projectParticipantState, projectParticipantDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());
	const [participantState, participantDispatch] = useReducer(participantReducer, getInitialState<IParticipant>());
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const [sectorState, sectorDispatch] = useReducer(sectorReducer, getInitialState<ISector>());
	const [contractTypeState, contractTypeDispatch] = useReducer(contractTypeReducer, getInitialState<IContractType>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [newCompanyState, newCompanyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [lastJobFunctionState, lastJobFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [preferedJobFunctionState, preferedJobFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [newJobFunctionState, newJobFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [, inNacebelCodeDispatch] = useReducer(nacebelCodeReducer, getInitialState<INacebelCode>());
	const [, outNacebelCodeDispatch] = useReducer(nacebelCodeReducer, getInitialState<INacebelCode>());
	const [, inJointCommitteeDispatch] = useReducer(jointCommitteeReducer, getInitialState<IJointCommittee>());
	const [, outJointCommitteeDispatch] = useReducer(jointCommitteeReducer, getInitialState<IJointCommittee>());

	const firstField: React.MutableRefObject<ComboBox> = useRef();
	const apiService: ApiCommunicator = useApiService();

	const [deleteInGroupsState, setDeleteInGroups] = useState<boolean>(false);
	const [deleteInSessionsState, setDeleteInSessions] = useState<boolean>(false);
	const [deleteInOtherProjectsState, setDeleteInOtherProjects] = useState<boolean>(false);

	useEffect(() => {
		if (projectParticipantState.entity) {
			setProjectParticipant(projectParticipantState.entity);
		}
	}, [projectParticipantState.entity]);

	useEffect(() => {
		apiService.callApi(nacebelCodeDispatch, Endpoint.NacebelCodes, "GET", { search: "" });
		apiService.callApi(jointCommitteeDispatch, Endpoint.JointCommittees, "GET", { search: "" });
	}, [apiService]);

	useEffect(() => {
		if (props.companyId) {
			apiService.callApi(companyDispatch, Endpoint.Companies, "GET", { id: props.companyId });
		}
	}, [props.companyId, apiService]);

	const addCompanyToProjectParticipant: (company: ICompany, isNewCompany: boolean) => void = useCallback((company: ICompany, isNewCompany: boolean) => {
		setProjectParticipant((oldParticipant: IProjectParticipant) => {
			const projectParticipantRecord: IProjectParticipant = cloneDeep(oldParticipant);
			const strCompany: keyof IProjectParticipant = isNewCompany ? "newCompany" : "company";
			const strCompanyId: keyof IProjectParticipant = isNewCompany ? "newCompanyId" : "companyId";

			if (company !== null) {
				const jointCommittees: IProjectParticipantJointCommittee[] = isNewCompany ? projectParticipantRecord.outgoingJointCommittees : projectParticipantRecord.incommingJointCommittees;
				const nacebelCodes: IProjectParticipantNacebelCode[] = isNewCompany ? projectParticipantRecord.outgoingNacebelCodes : projectParticipantRecord.incommingNacebelCodes;

				for (const code of company?.jointCommittees) {
					jointCommittees.push({
						jointCommitteeId: code.jointCommitteeId,
						jointCommittee: code.jointCommittee,
						projectParticipantId: projectParticipant.id
					});
				}
				for (const code of company?.nacebelCodes) {
					nacebelCodes.push({
						nacebelCodeId: code.nacebelCodeId,
						nacebelCode: code.nacebelCode,
						projectParticipantId: projectParticipant.id
					});
				}
			}
			return setEntity(projectParticipantRecord, company, strCompany, strCompanyId);
		});
		setDataChanged(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (companyState.entity) {
			addCompanyToProjectParticipant(companyState.entity, false);
		}
	}, [companyState.entity, addCompanyToProjectParticipant]);

	function onChange(
		event: InputChangeEvent | SwitchChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent | DatePickerChangeEvent
	): void {
		if (event.target.name === "status" && ["Stopped", "Aftercare"].includes((event as DropDownListChangeEvent).value.key)) {
			const deleteParticipantKey: string = newKey("deleteParticipant");
			reduxDispatch(
				render(deleteParticipantKey, ParticipantStatusEditor, {
					onClose: () => reduxDispatch(derender(deleteParticipantKey)),
					onSave: (deleteInGroups: boolean, deleteInSessions: boolean, deleteInOtherProjects: boolean) => {
						setDeleteInGroups(deleteInGroups);
						setDeleteInSessions(deleteInSessions);
						setDeleteInOtherProjects(deleteInOtherProjects);
						reduxDispatch(derender(deleteParticipantKey));
					}
				})
			);
		}

		const updatedProjectParticipant: IProjectParticipant = handleChange(projectParticipant, event);

		if (event.target.name === "companyId" || event.target.name === "newCompanyId") {
			const company: ICompany = (event as DropDownListChangeEvent).value;
			const isNewCompany: boolean = event.target.name === "newCompanyId";

			if (company !== null) {
				const jointCommittees: IProjectParticipantJointCommittee[] = isNewCompany ? updatedProjectParticipant.outgoingJointCommittees : updatedProjectParticipant.incommingJointCommittees;
				const nacebelCodes: IProjectParticipantNacebelCode[] = isNewCompany ? updatedProjectParticipant.outgoingNacebelCodes : updatedProjectParticipant.incommingNacebelCodes;

				for (const code of company?.jointCommittees) {
					jointCommittees.push({
						jointCommitteeId: code.jointCommitteeId,
						jointCommittee: code.jointCommittee,
						projectParticipantId: projectParticipant.id
					});
				}
				for (const code of company?.nacebelCodes) {
					nacebelCodes.push({
						nacebelCodeId: code.nacebelCodeId,
						nacebelCode: code.nacebelCode,
						projectParticipantId: projectParticipant.id
					});
				}
			}
		}

		if (event.target.name === "participantId") {
			if (updatedProjectParticipant.participant.dateOfBirth) {
				const today: Date = new Date();
				const birthDay: Date = new Date(updatedProjectParticipant.participant.dateOfBirth);
				const differenceInDays: number = (today.getTime() - birthDay.getTime()) / (1000 * 60 * 60 * 24);
				const differenceInYears: number = differenceInDays / 365.25;

				switch (true) {
					case differenceInYears >= 45:
						updatedProjectParticipant.ageClass = AgeClass.Plus45;
						break;
					case differenceInYears < 45:
						updatedProjectParticipant.ageClass = AgeClass.Minus45;
						break;
				}
			}
		}

		setProjectParticipant(updatedProjectParticipant);
		setDataChanged(true);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const newProjectParticipant: IProjectParticipant = cloneDeep(projectParticipant);

		if (event.value) {
			switch (event.target.name) {
				case "sectors":
					newProjectParticipant.sectors = [];
					for (const sector of event.value) {
						newProjectParticipant.sectors.push({
							participantId: newProjectParticipant.id,
							sectorId: sector.id,
							sector
						});
					}
					break;
				case "incommingJointCommittee":
					newProjectParticipant.incommingJointCommittees = [];
					for (const jointCommittee of event.value) {
						newProjectParticipant.incommingJointCommittees.push({
							projectParticipantId: newProjectParticipant.id,
							jointCommitteeId: jointCommittee.id,
							jointCommittee
						});
					}
					break;
				case "outgoingJointCommittee":
					newProjectParticipant.outgoingJointCommittees = [];
					for (const jointCommittee of event.value) {
						newProjectParticipant.outgoingJointCommittees.push({
							projectParticipantId: newProjectParticipant.id,
							jointCommitteeId: jointCommittee.id,
							jointCommittee
						});
					}
					break;
				case "incommingNacebelCode":
					newProjectParticipant.incommingNacebelCodes = [];
					for (const nacebelCode of event.value) {
						newProjectParticipant.incommingNacebelCodes.push({
							projectParticipantId: newProjectParticipant.id,
							nacebelCodeId: nacebelCode.id,
							nacebelCode
						});
					}
					break;
				case "outgoingNacebelCode":
					newProjectParticipant.outgoingNacebelCodes = [];
					for (const nacebelCode of event.value) {
						newProjectParticipant.outgoingNacebelCodes.push({
							projectParticipantId: newProjectParticipant.id,
							nacebelCodeId: nacebelCode.id,
							nacebelCode
						});
					}
					break;
			}
			updateProjectParticipant(newProjectParticipant);
		}
	}

	function addOrUpdateSector(sector: ISector): void {
		const newProjectParticipant: IProjectParticipant = cloneDeep(projectParticipant);
		const existingProjectParticipantSector: IProjectParticipantSector = find(newProjectParticipant.sectors, { sectorId: sector.id });
		if (existingProjectParticipantSector) {
			existingProjectParticipantSector.sector = sector;
		} else {
			newProjectParticipant.sectors.push({
				participantId: newProjectParticipant.id,
				sectorId: sector.id,
				sector
			});
		}
		updateProjectParticipant(newProjectParticipant);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IProjectParticipant, idField: keyof IProjectParticipant): void {
		const newEntity: IProjectParticipant = setEntity(projectParticipant, entity, field, idField);
		updateProjectParticipant(newEntity);
	}

	function updateProjectParticipant(newProjectParticipant: IProjectParticipant): void {
		setProjectParticipant(newProjectParticipant);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "participantId":
				dispatch = participantDispatch;
				filterEndpoint = Endpoint.Participants;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
			case "contractTypeId":
				dispatch = contractTypeDispatch;
				filterEndpoint = Endpoint.ContractTypes;
				break;
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "newCompanyId":
				dispatch = newCompanyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "lastFunctionId":
				dispatch = lastJobFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
			case "newJobFunctionId":
				dispatch = newJobFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
			case "preferedFutureFunctionId":
				dispatch = preferedJobFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
			case "incommingNacebelCodeId":
				dispatch = inNacebelCodeDispatch;
				filterEndpoint = Endpoint.NacebelCodes;
				break;
			case "outgoingNacebelCodeId":
				dispatch = outNacebelCodeDispatch;
				filterEndpoint = Endpoint.NacebelCodes;
				break;
			case "incommingJointCommitteeId":
				dispatch = inJointCommitteeDispatch;
				filterEndpoint = Endpoint.JointCommittees;
				break;
			case "outgoingJointCommitteeId":
				dispatch = outJointCommitteeDispatch;
				filterEndpoint = Endpoint.JointCommittees;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function addMutliSelectItem(item: INacebelCode | IJointCommittee, type: "outgoingJointCommittee" | "incommingJointCommittee" | "outgoingNacebelCode" | "incommingNacebelCode"): void {
		const projectParticipantRecord: IProjectParticipant = cloneDeep(projectParticipant);
		let joinObject: IProjectParticipantNacebelCode | IProjectParticipantJointCommittee;
		let joinArray: IProjectParticipantNacebelCode[] | IProjectParticipantJointCommittee[];
		let idField: "nacebelCodeId" | "jointCommitteeId";

		switch (type) {
			case "incommingNacebelCode":
				joinArray = projectParticipantRecord.incommingNacebelCodes;
				idField = "nacebelCodeId";
				break;
			case "outgoingNacebelCode":
				joinArray = projectParticipantRecord.outgoingNacebelCodes;
				idField = "nacebelCodeId";
				break;
			case "outgoingJointCommittee":
				joinArray = projectParticipantRecord.outgoingJointCommittees;
				idField = "jointCommitteeId";
				break;
			case "incommingJointCommittee":
				joinArray = projectParticipantRecord.incommingJointCommittees;
				idField = "jointCommitteeId";
				break;
		}

		joinObject = find(joinArray, { [idField]: item.id }) as IProjectParticipantNacebelCode | IProjectParticipantJointCommittee;

		if (joinObject) {
			switch (type) {
				case "incommingNacebelCode":
				case "outgoingNacebelCode":
					(joinObject as IProjectParticipantNacebelCode).nacebelCode = item;
					break;
				case "outgoingJointCommittee":
				case "incommingJointCommittee":
					(joinObject as IProjectParticipantJointCommittee).jointCommittee = item;
					break;
			}
		} else {
			switch (type) {
				case "incommingNacebelCode":
				case "outgoingNacebelCode":
					joinObject = {
						projectParticipantId: projectParticipantRecord.id,
						nacebelCodeId: item.id,
						nacebelCode: item
					};
					break;
				case "outgoingJointCommittee":
				case "incommingJointCommittee":
					joinObject = {
						projectParticipantId: projectParticipantRecord.id,
						jointCommitteeId: item.id,
						jointCommittee: item
					};
					break;
			}
			joinArray.push(joinObject as IProjectParticipantNacebelCode & IProjectParticipantJointCommittee);
		}
		updateProjectParticipant(projectParticipantRecord);
	}

	function onMultiSelectFilterChange(event: MultiSelectFilterChangeEvent): void {
		apiService.callApi(sectorDispatch, Endpoint.Sectors, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!projectParticipant.participantId) {
			messages.push(t("fill_in_required_field", { field: t("participant").toLowerCase() }));
		}
		if (!projectParticipant.responsibleUserId) {
			messages.push(t("fill_in_required_field", { field: t("projectParticipantResponsible").toLowerCase() }));
		}
		if (!projectParticipant.status) {
			messages.push(t("fill_in_required_field", { field: t("projectParticipantStatus").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.Stopped && isNullOrEmpty(projectParticipant.stopReason)) {
			messages.push(t("fill_in_required_field", { field: t("stopReason").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.Stopped && !projectParticipant.stopDate) {
			messages.push(t("fill_in_required_field", { field: t("stopDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.stopDate) && projectParticipant.stopDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("stopDate").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.OnHold && isNullOrEmpty(projectParticipant.onHoldReason)) {
			messages.push(t("fill_in_required_field", { field: t("onHoldReason").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.OnHold && !projectParticipant.onHoldDate) {
			messages.push(t("fill_in_required_field", { field: t("onHoldDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.onHoldDate) && projectParticipant.onHoldDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("onHoldDate").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.NotStarted && isNullOrEmpty(projectParticipant.notStartedReason)) {
			messages.push(t("fill_in_required_field", { field: t("notStartedReason").toLowerCase() }));
		}
		if (projectParticipant.status === ProjectParticipantStatus.NotStarted && !projectParticipant.notStartedDate) {
			messages.push(t("fill_in_required_field", { field: t("notStartedDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.onHoldDate) && projectParticipant.notStartedDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("notStartedDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.fireOrQuitDate) && projectParticipant.fireOrQuitDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("fireOrQuitDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.employmentUnitEnrollDate) && projectParticipant.employmentUnitEnrollDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("employmentUnitEnrollDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.employmentUnitLeaveDate) && projectParticipant.employmentUnitLeaveDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("employmentUnitLeaveDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.noticePeriodEndDate) && projectParticipant.noticePeriodEndDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("noticePeriodEndDate").toLowerCase() }));
		}
		if (!isNull(projectParticipant.newEmploymentDate) && projectParticipant.newEmploymentDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("newEmploymentDate").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IProjectParticipant): void {
		if (record && !close) {
			setProjectParticipant(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function manageIntake(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, record?: IIntake): void {
		const key: string = newKey("add_intake");
		reduxDispatch(
			render(key, IntakeEditor, {
				projectId: (props as IProps).projectId,
				participantId: projectParticipant.id,
				onClose: () => reduxDispatch(derender(key))
			})
		);
	}

	function renderProjectParticipantStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getProjectParticipantStatusColor);
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: IProjectParticipantProps = {
			projectParticipantId: projectParticipant.id,
			projectId: props.projectId
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						// console.log("email");
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	function getParticipantAgeClass(): AgeClass {
		if (!projectParticipant.ageClass) {
			if (projectParticipant.participant.dateOfBirth) {
				const today: Date = new Date();
				const birthDay: Date = new Date(projectParticipant.participant.dateOfBirth);
				const differenceInDays: number = (today.getTime() - birthDay.getTime()) / (1000 * 60 * 60 * 24);
				const differenceInYears: number = differenceInDays / 365.25;

				switch (true) {
					case differenceInYears >= 45:
						return AgeClass.Plus45;
					case differenceInYears < 45:
						return AgeClass.Minus45;
				}
			}
		}
		return projectParticipant.ageClass;
	}

	function deleteCompany(isNewCompany: boolean): void {
		const projectParticipantRecord: IProjectParticipant = cloneDeep(projectParticipant);
		const strCompany: keyof IProjectParticipant = isNewCompany ? "newCompany" : "company";
		const strCompanyId: keyof IProjectParticipant = isNewCompany ? "newCompanyId" : "companyId";

		if (isNewCompany) {
			projectParticipantRecord.outgoingJointCommittees = [];
			projectParticipantRecord.outgoingNacebelCodes = [];
		} else {
			projectParticipantRecord.incommingJointCommittees = [];
			projectParticipantRecord.incommingNacebelCodes = [];
		}

		const newEntity: IProjectParticipant = setEntity(projectParticipantRecord, undefined, strCompany, strCompanyId);
		updateProjectParticipant(newEntity);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IProjectParticipant>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={projectParticipant}
			endpoint={Endpoint.ProjectParticipants}
			extraUrlParams={{ projectId: props.projectId, deleteInGroups: deleteInGroupsState, deleteInSessions: deleteInSessionsState, deleteInOtherProjects: deleteInOtherProjectsState }}
			entityState={projectParticipantState}
			entityType={t("projectParticipant")}
			dispatch={projectParticipantDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={projectParticipant.participant?.fullName}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						{hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate) && projectParticipant.id > 0 && (
							<div className="k-form-field" style={{ padding: "5px 0px 20px 0px" }}>
								<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
									{t("sendEmail")}
								</StandardButton>
							</div>
						)}
						<ManageableField
							fieldLabel={`${t("participant")} *`}
							recordId={projectParticipant.participantId}
							addScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsAdd) }}
							editScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsUpdate) }}
							setEntity={(participant: IParticipant) => setChild(participant, "participant", "participantId")}
							readOnly={readonly}
						>
							<SearchBox
								name="participantId"
								entities={participantState.entities}
								isLoading={participantState.areEntitiesLoading}
								entityId={projectParticipant.participantId}
								entity={projectParticipant.participant}
								textField="fullName"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(emptyParticipant, "participant", "participantId")}
								disabled={readonly}
								myRef={firstField}
							/>
						</ManageableField>
						<ParticipantEditorContent participant={projectParticipant.participant} readonly hideActive={false} />
					</div>
				</Tab>
				<Tab reactKey="project" label={t("project")}>
					<div className="k-form">
						<div className="row">
							{props.statusChange && (
								<div className="col-4">
									<div className="k-form-field">
										<span>{t("projectParticipantStatus")} *</span>
										<EnumDropDownList
											name="status"
											value={projectParticipant.status}
											onChange={onChange}
											enum={ProjectParticipantStatus}
											itemRender={renderProjectParticipantStatus}
											required
											disabled={readonly}
										/>
									</div>
								</div>
							)}
							{projectParticipant.status === ProjectParticipantStatus.Stopped && props.statusChange && (
								<>
									<div className="col">
										<label className="k-form-field">
											<span>{t("stopReason")} *</span>
											<Input name="stopReason" value={projectParticipant.stopReason} required onChange={onChange} disabled={readonly} />
										</label>
									</div>
									<div className="col">
										<div className="k-form-field">
											<DatePickerForDateOnly
												label={"stopDate"}
												name="stopDate"
												value={getDate(projectParticipant.stopDate)}
												formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
												format={"yyyy-MM-dd"}
												onChange={onChange}
												disabled={readonly}
												required
											/>
										</div>
									</div>
								</>
							)}
							{projectParticipant.status === ProjectParticipantStatus.OnHold && props.statusChange && (
								<>
									<div className="col">
										<label className="k-form-field">
											<span>{t("onHoldReason")} *</span>
											<Input name="onHoldReason" value={projectParticipant.onHoldReason} required onChange={onChange} disabled={readonly} />
										</label>
									</div>
									<div className="col">
										<div className="k-form-field">
											<DatePickerForDateOnly
												label={"onHoldDate"}
												name="onHoldDate"
												value={getDate(projectParticipant.onHoldDate)}
												formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
												format={"yyyy-MM-dd"}
												onChange={onChange}
												disabled={readonly}
												required
											/>
										</div>
									</div>
								</>
							)}
							{projectParticipant.status === ProjectParticipantStatus.NotStarted && props.statusChange && (
								<>
									<div className="col">
										<label className="k-form-field">
											<span>{t("notStartedReason")} *</span>
											<Input name="notStartedReason" value={projectParticipant.notStartedReason} required onChange={onChange} disabled={readonly} />
										</label>
									</div>
									<div className="col">
										<div className="k-form-field">
											<DatePickerForDateOnly
												label={"notStartedDate"}
												name="notStartedDate"
												value={getDate(projectParticipant.notStartedDate)}
												formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
												format={"yyyy-MM-dd"}
												onChange={onChange}
												disabled={readonly}
												required
											/>
										</div>
									</div>
								</>
							)}
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("projectParticipantResponsible")}</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={projectParticipant.responsibleUserId}
										entity={projectParticipant.responsibleUser}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "responsibleUser", "responsibleUserId")}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("ageClass")}</span>
									<EnumDropDownList name="ageClass" value={getParticipantAgeClass()} enum={AgeClass} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("statute")}</span>
									<EnumDropDownList name="statute" value={projectParticipant.statute} enum={ProjectParticipantStatute} onChange={onChange} disabled={readonly} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("sectors")}
									addScreen={{ screen: SectorEditor, isAllowed: hasPermission(Permission.SectorsAdd) }}
									editScreen={{ screen: SectorEditor, isAllowed: hasPermission(Permission.SectorsUpdate) }}
									setEntity={addOrUpdateSector}
									name="sectors"
									data={sectorState.entities}
									textField="name"
									dataItemKey="id"
									value={map(projectParticipant.sectors, "sector")}
									loading={sectorState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									filterable
									onFilterChange={onMultiSelectFilterChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"fireOrQuitDate"}
										name="fireOrQuitDate"
										value={getDate(projectParticipant.fireOrQuitDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"noticePeriodEndDate"}
										name="noticePeriodEndDate"
										value={getDate(projectParticipant.noticePeriodEndDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("contractType")}
									recordId={projectParticipant.contractTypeId}
									addScreen={{ screen: ContractTypeEditor, isAllowed: hasPermission(Permission.ContractTypesAdd) }}
									editScreen={{ screen: ContractTypeEditor, isAllowed: hasPermission(Permission.ContractTypesUpdate) }}
									setEntity={(contractType: IContractType) => setChild(contractType, "contractType", "contractTypeId")}
									readOnly={readonly}
								>
									<SearchBox
										name="contractTypeId"
										entities={contractTypeState.entities}
										isLoading={contractTypeState.areEntitiesLoading}
										entityId={projectParticipant.contractTypeId}
										entity={projectParticipant.contractType}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "contractType", "contractTypeId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("company")}
									recordId={projectParticipant.companyId}
									addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
									editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
									setEntity={(company: ICompany) => addCompanyToProjectParticipant(company, false)}
									readOnly={readonly}
								>
									<SearchBox
										name="companyId"
										entities={companyState.entities}
										isLoading={companyState.areEntitiesLoading}
										entityId={projectParticipant.companyId}
										entity={projectParticipant.company}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => deleteCompany(false)}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("lastFunction")}
									recordId={projectParticipant.lastFunctionId}
									addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
									editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
									setEntity={(lastFunction: IJobFunction) => setChild(lastFunction, "lastFunction", "lastFunctionId")}
									readOnly={readonly}
								>
									<SearchBox
										name="lastFunctionId"
										entities={lastJobFunctionState.entities}
										isLoading={lastJobFunctionState.areEntitiesLoading}
										entityId={projectParticipant.lastFunctionId}
										entity={projectParticipant.lastFunction}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "lastFunction", "lastFunctionId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("preferedFutureFunction")}
									recordId={projectParticipant.preferedFutureFunctionId}
									addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
									editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
									setEntity={(jobFunction: IJobFunction) => setChild(jobFunction, "preferedFutureFunction", "preferedFutureFunctionId")}
									readOnly={readonly}
								>
									<SearchBox
										name="preferedFutureFunctionId"
										entities={preferedJobFunctionState.entities}
										isLoading={preferedJobFunctionState.areEntitiesLoading}
										entityId={projectParticipant.preferedFutureFunctionId}
										entity={projectParticipant.preferedFutureFunction}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "preferedFutureFunction", "preferedFutureFunctionId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"employmentUnitEnrollDate"}
										name="employmentUnitEnrollDate"
										value={getDate(projectParticipant.employmentUnitEnrollDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"employmentUnitLeaveDate"}
										name="employmentUnitLeaveDate"
										value={getDate(projectParticipant.employmentUnitLeaveDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col offset-4">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"startDateIV1"}
										name="startDateIV1"
										value={getDate(projectParticipant.startDateIV1)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"startDateIV2"}
										name="startDateIV2"
										value={getDate(projectParticipant.startDateIV2)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("putNameOnInvoice")}</div>
									<YesNoSwitch name="putNameOnInvoice" checked={projectParticipant.putNameOnInvoice} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("reclamation")}</div>
									<YesNoSwitch name="reclamation" checked={projectParticipant.reclamation} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("outplacementCertificate")}</div>
									<YesNoSwitch name="outplacementCertificate" checked={projectParticipant.outplacementCertificate} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("accessToTraP")}</div>
									<YesNoSwitch name="accessToTraP" checked={projectParticipant.accessToTraP} onChange={onChange} disabled={readonly} />
								</div>
							</div>
						</div>
					</div>
				</Tab>
				<Tab reactKey="info" label={t("Info")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<StandardButton primary onClick={manageIntake}>
									{t("intake")}
								</StandardButton>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("newJobFunction")}
									recordId={projectParticipant.newJobFunctionId}
									addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
									editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
									setEntity={(jobFunction: IJobFunction) => setChild(jobFunction, "newJobFunction", "newJobFunctionId")}
									readOnly={readonly}
								>
									<SearchBox
										name="newJobFunctionId"
										entities={newJobFunctionState.entities}
										isLoading={newJobFunctionState.areEntitiesLoading}
										entityId={projectParticipant.newJobFunctionId}
										entity={projectParticipant.newJobFunction}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "newJobFunction", "newJobFunctionId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("newCompany")}
									recordId={projectParticipant.newCompanyId}
									addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
									editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
									setEntity={(company: ICompany) => addCompanyToProjectParticipant(company, true)}
									readOnly={readonly}
								>
									<SearchBox
										name="newCompanyId"
										entities={newCompanyState.entities}
										isLoading={newCompanyState.areEntitiesLoading}
										entityId={projectParticipant.newCompanyId}
										entity={projectParticipant.newCompany}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => deleteCompany(true)}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"newEmploymentDate"}
										name="newEmploymentDate"
										value={getDate(projectParticipant.newEmploymentDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("incommingNacebelCode")}
									addScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesAdd) }}
									editScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesUpdate) }}
									setEntity={(record: INacebelCode) => addMutliSelectItem(record, "incommingNacebelCode")}
									name="incommingNacebelCode"
									data={nacebelCodeState.entities}
									textField="name"
									dataItemKey="id"
									value={map(projectParticipant.incommingNacebelCodes, "nacebelCode")}
									filterable
									loading={nacebelCodeState.areEntitiesLoading}
									onFilterChange={onMultiSelectFilterChange}
									onChange={onMultiSelectChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("outgoingNacebelCode")}
									addScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesAdd) }}
									editScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesUpdate) }}
									setEntity={(record: INacebelCode) => addMutliSelectItem(record, "outgoingNacebelCode")}
									name="outgoingNacebelCode"
									data={nacebelCodeState.entities}
									textField="name"
									dataItemKey="id"
									value={map(projectParticipant.outgoingNacebelCodes, "nacebelCode")}
									filterable
									loading={nacebelCodeState.areEntitiesLoading}
									onFilterChange={onMultiSelectFilterChange}
									onChange={onMultiSelectChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("incommingJointCommittee")}
									addScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesAdd) }}
									editScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesUpdate) }}
									setEntity={(record: IJointCommittee) => addMutliSelectItem(record, "incommingJointCommittee")}
									name="incommingJointCommittee"
									data={jointCommitteeState.entities}
									textField="name"
									dataItemKey="id"
									value={map(projectParticipant.incommingJointCommittees, "jointCommittee")}
									filterable
									loading={jointCommitteeState.areEntitiesLoading}
									onFilterChange={onMultiSelectFilterChange}
									onChange={onMultiSelectChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("outgoingJointCommittee")}
									addScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesAdd) }}
									editScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesUpdate) }}
									setEntity={(record: IJointCommittee) => addMutliSelectItem(record, "outgoingJointCommittee")}
									name="outgoingJointCommittee"
									data={jointCommitteeState.entities}
									textField="name"
									dataItemKey="id"
									value={map(projectParticipant.outgoingJointCommittees, "jointCommittee")}
									filterable
									loading={jointCommitteeState.areEntitiesLoading}
									onFilterChange={onMultiSelectFilterChange}
									onChange={onMultiSelectChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<div>{t("contractSent")}</div>
									<YesNoSwitch name="contractSent" checked={projectParticipant.contractSent} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<div>{t("contractSignedReceived")}</div>
									<YesNoSwitch name="contractSignedReceived" checked={projectParticipant.contractSignedReceived} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<div>{t("contractSentToGovernment")}</div>
									<YesNoSwitch name="contractSentToGovernment" checked={projectParticipant.contractSentToGovernment} onChange={onChange} disabled={readonly} />
								</div>
							</div>
						</div>
					</div>
				</Tab>
				{projectParticipant.id > 0 && (
					<Tab reactKey="projectParticipantServices" label={t("services")}>
						<ProjectParticipantServices projectId={props.projectId} participantId={projectParticipant.id} />
					</Tab>
				)}
				{projectParticipant.id > 0 && (
					<Tab reactKey="tasks" label={t("tasks")}>
						<ProjectParticipantFollowUpTasks projectId={props.projectId} participantId={projectParticipant.id} />
					</Tab>
				)}
				{projectParticipant.id > 0 && (
					<Tab reactKey="notes" label={t("notes")}>
						<ProjectParticipantNotes projectParticipantId={projectParticipant.id} />
					</Tab>
				)}
				{projectParticipant.id > 0 && (
					<Tab reactKey="emails" label={t("emails")}>
						<ProjectParticipantEmails projectParticipantId={projectParticipant.id} projectId={props.projectId} />
					</Tab>
				)}
				{projectParticipant.id > 0 && (
					<Tab reactKey="attachments" label={t("attachments")}>
						<ProjectParticipantAttachments projectParticipantId={projectParticipant.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default ProjectParticipantEditor;
