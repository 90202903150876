import ITranslation from "./translation";

const translationsEN: ITranslation = {
	absenceReason: "Absence Reason",
	absenceReasons: "Absence Reasons",
	absent: "Absent",
	accepted: "Accepted",
	account: "Account",
	actions: "Actions",
	active: "Active",
	activeProvinces: "Active provinces",
	actualStart: "Actual start",
	actualStop: "Actual stop",
	add: "Add",
	add_at_least_one: "Please add at lease 1 {{entity}}.",
	addIntake: "Add intake",
	Aftercare: "Aftercare",
	ageClass: "Age class",
	allParticipants: "All participants",
	ambitions: "Ambitions",
	amount: "Amount",
	and: "and",
	annaFromTravvant: "Anna of Travvant",
	anomaly: "Anomaly",
	Approved: "Approved",
	ask_close: "There are unsaved changes. Are you sure you want to close this window?",
	ask_save: "There are still unsaved changes. Do you wish to save them?",
	assignee: "Assingee",
	attachment: "Attachment",
	attachments: "Attachments",
	attachmentsTooLarge: "The selected attachment together are bigger than 10MB. Please select less.",
	attachTo: "Attach to",
	attendance: "Attendence",
	backOfficeTasks: "Tasks",
	bcc: "Bcc",
	begin: "Start",
	birthdayCard: "Birthday card",
	body: "Body",
	bodyDe: "Body DE",
	bodyEn: "Body EN",
	bodyFr: "Body FR",
	bodyNl: "Body NL",
	building: "Gebouw",
	calendar: {
		today: "TODAY"
	},
	cancel: "Cancel",
	cancelInvitation: "Cancel invitation",
	Cancelled: "Cancelled",
	catalogue: "Catalogue",
	catalogueService: "Catalogue service",
	catering: "Catering",
	cateringPrice: "Catering price",
	cc: "Cc",
	chanceOfSucces: "Change of success",
	changeStatusError: "Failed, please try again",
	changeStatusSuccess: "Status changed to 'to be invoiced'",
	changeStatusToInvoice: "Change status to 'to be invoiced'",
	checkList: "Check list",
	chooseFilter: "Choose filter",
	close: "Close",
	Closed: "Closed",
	coach: "Coach",
	coaches: "Coaches",
	coachSatisfactionQuestion: "How satisfied are you with the way your coach guided this outplacement session",
	code: "Code",
	collectiveHours: "Collective hours",
	commissionPercentage: "Comission percentage",
	communicationLanguage: "Communication language",
	companies: "Companies",
	company: "Company",
	companyContact: "Company contact",
	companyGroup: "Company group",
	companyGroups: "Company groups",
	companyHeat: "Company heat",
	Completed: "Completed",
	confirm_cancel_invitation: "Are you sure you wish to cancel this invitation?",
	confirm_content: "Are you sure you wish to {{action}} this record?",
	confirm_currentuser_activechange: "You are deactivating your own account. If you save this, you won't be able to log in to the application. Do you whish to continue?",
	confirm_currentuser_rolechange: "You are changing the roles of you own account. It is possible you cannot reverse this yourselve. Do you whish to continue?",
	confirm_file_remove: "Are you sure you want to delete the file?",
	confirm_message_all_filled_in: "Are you sure you have entered everything correctly?",
	confirm_overwrite_field: "Are you sure you want to overwrite the field '{{field}}'?",
	confirm_overwrite_session_template: "Do you want to overwrite the session data with the template data?",
	confirm_reset: "Are you sure you wish to reset the settings?",
	confirm_role_update_current_user: "You are changing a role attached to your account. It is possible you cannot reverse this yourselve. Do you whish to continue?",
	confirm_title: "Confirm {{action}}",
	confirmLeave: "You have unsaved data, are you sure you want to leave without saving your changes?",
	contact: "Contact",
	contacts: "Contacts",
	contactType: "Contact Type",
	contactTypes: "Contact Types",
	content: "Content",
	contractSent: "Contract sent",
	contractSentToGovernment: "Contract sent to government",
	contractSignedReceived: "Contract signed received",
	contractType: "Contract Type",
	contractTypes: "Contract Types",
	copy: "Copy",
	copyProject: "Copy project",
	copyProjectFromProject: "Copy project",
	copyServices: "Copy services",
	cost: "Cost €",
	country: "Country",
	create: "Create",
	createdDate: "Created on",
	createDocument: "Create document",
	createdOn: "Created on",
	createFromContact: "Create from contact",
	createNew: "This <1 /> is not found.<3 />Do you wish to create a new one?",
	createOpportunityFromProject: "Create opportunity from project",
	createProject: "Create project",
	createProjectFromOpportunity: "Create project of oportunity",
	createProjectSucces: "Project created successfully",
	creator: "Creator",
	customer: "Customer",
	customerPONumber: "Customer PO number",
	customerShow: "Show customer",
	customRecipient: "Custom recipient",
	Daily: "Daily",
	date: "Date",
	dateinput: {
		decrement: "Decrease value",
		increment: "Increase value"
	},
	dateOfBirth: "Date of birth",
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	datetimepicker: {
		cancel: "Cancel",
		date: "Date",
		set: "Set",
		time: "Time",
		toggleDateTimeSelector: "Toggle date-time selector"
	},
	day: "day",
	Deelnemer: "Participant",
	degree: "Degree",
	degrees: "Degrees",
	delete: "Delete",
	description: "Description",
	details: "Details",
	digitalInteractionSatisfactionQuestion: "What did you think about the interaction of the digital outplacement guidance",
	displayedUsers: "Filter displayed users",
	distributeParticipants: "Distribute participants",
	document: "Document",
	documentName: "Document name",
	documentNameDe: "Document name DE",
	documentNameEn: "Document name EN",
	documentNameFr: "Document name FR",
	documentNameNl: "Document name NL",
	documentRequestedSuccessfully: "Document(s) request succeeded",
	documentTemplate: "Document template",
	documentTemplates: "Document templates",
	DoNotInvoice: "Do not invoice",
	downloadParticipantsTemplate: "Download participants template",
	downloadProjectParticipants: "Download project participants",
	dueDate: "Due date",
	Dutch: "Dutch",
	edit: "Edit",
	editRelatedEntity: "Edit related entity",
	education: "Education",
	email: "Email",
	emailRequestedSuccessfully: "Email(s) request succeeded",
	emails: "Emails",
	emailTemplate: "Email template",
	emailTemplates: "Email templates",
	employmentConditions: "Employment condiotions",
	employmentUnitEnrollDate: "Enrollment date",
	employmentUnitLeaveDate: "Leave date",
	en: "English",
	end: "End",
	English: "English",
	environmentConditions: "Conditions environment",
	error: "Error",
	errorLog: "Error log",
	errorLogId: "Errorlog ID",
	errors: {
		error_argumentnull: "An {{argument}} was empty in the serverside",
		error_connection: "Unable to make an external connection",
		error_constraintfailure: "A database contstraint prevented this action",
		error_dbconcurrency: "This record is changed or deleted by someone else. Please refresh.",
		error_deleteFKConstraintStillUsed: "You're trying to delete an entity that still gets used in other entities. ",
		error_duplicate: "A record with this {{uniqueKey}} allready exists",
		error_entityused: "This entity is still used in the application. If you don't want to see it anymore, please deactivate it",
		error_general: "An error on the servers side has occured",
		error_idfilledin: "The ID cannot be filled in while adding an entity",
		error_idnotfilledin: "The ID must be filled in when deleteing",
		error_nofile: "No file provided",
		error_notAllServicesAreInvoiced: "Please make sure all services are invoiced before you finish a project",
		error_routeiddoesnotmatch: "The ID in the route does not match the ID in the entity",
		error_server_validation: "Server validation error",
		error_sqlconnection: "Unable to make a database connection",
		error_sqlgeneral: "A database error occured",
		error_validation: "The server side validation failed",
		error_vatnumber: "An error happened for the provided VAT number",
		forbidden: "You don't have access to this part of the server application",
		not_authenticated: "You are not logged in at the server",
		not_found: "The server did not find a result",
		server_error: "The server returned a '{{statuscode}}' response."
	},
	example: "Example",
	excelExport: "Export selected fields to excel",
	excelImport: "Import excel",
	excelImportRequest: "The request for updating the invoice data was succesfull.",
	Executive: "Executive",
	expertise: "Expertise",
	expertises: "Expertises",
	extra: "Extra",
	facilities: "Facilities",
	facility: "Facility",
	Female: "F",
	file: "File",
	fill_in_correct_date: "Please fill in a date after 1900 for the field '{{field}}'",
	fill_in_correct_format: "Please fill in a correct value for the field '{{field}}'. Ex: '{{example}}'.",
	fill_in_correct_planned_hours: "Please fill in a correct date/time so that '{{begin}}' comes before '{{end}}'.",
	fill_in_required_field: "Please fill in the required field '{{field}}'.",
	Finished: "Finished",
	fireOrQuitDate: "Fire/quit date",
	firstName: "First name",
	firstSessionDate: "First Session",
	followUpTaskStatus: "Taskstatus",
	for: "For",
	Forecast: "Forecast",
	forecastCost: "Forecast cost €",
	fr: "French",
	French: "French",
	from: "From",
	fullName: "Full name",
	functionConditions: "Conditions function content",
	functionPreviousEmployment: "Function at previous employment",
	gender: "Gender",
	general: "General",
	German: "German",
	governmentService: "Government service",
	governmentServices: "Government services",
	grid: {
		filterAfterOperator: "Is after",
		filterAfterOrEqualOperator: "Is after or equal to",
		filterAndLogic: "And",
		filterBeforeOperator: "Is before",
		filterBeforeOrEqualOperator: "Is before or equal to",
		filterBooleanAll: "(All)",
		filterBooleanIsNull: "Undetermined",
		filterClearButton: "Clear",
		filterContainsOperator: "Contains",
		filterEndsWithOperator: "Ends with",
		filterEqOperator: "Is equal to",
		filterGteOperator: "Is greater than or equal to",
		filterGtOperator: "Is greater than",
		filterIsEmptyOperator: "Is empty",
		filterIsFalse: "No",
		filterIsNotEmptyOperator: "Is not empty",
		filterIsNotNullOperator: "Is not null",
		filterIsNullOperator: "Is null",
		filterIsTrue: "Yes",
		filterLteOperator: "Is less than or equal to",
		filterLtOperator: "Is less than",
		filterNotContainsOperator: "Does not contain",
		filterNotEqOperator: "Is not equal to",
		filterOrLogic: "Or",
		filterStartsWithOperator: "Starts with",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		groupPanelEmpty: "Drag a column header and drop it here to group by that column",
		noRecords: "No records available",
		pagerFirstPage: "Go to the first page",
		pagerInfo: "{0} - {1} of {2} items",
		pagerItemsPerPage: "items per page",
		pagerLastPage: "Go to the last page",
		pagerNextPage: "Go to the next page",
		pagerOf: "of",
		pagerPage: "Page",
		pagerPreviousPage: "Go to the previous page",
		sortAscending: "Sort Ascending",
		sortDescending: "Sort Descending"
	},
	group: "Group",
	groupCode: "Group code",
	groupFollowUpTaskStatus: "Grouptask status",
	groupInfo: "Group information",
	groupOccupationOrange: "Group occupation % - Orange",
	groupOccupationRed: "Group occupation % - Red",
	groupResponsible: "Group responsible",
	groups: "Groups",
	heat: "Heat",
	help: "Help",
	hideColumn: "Hide column",
	High: "High",
	highestDegree: "Highest degree",
	history: "History",
	home: "Home",
	import: "Import",
	importance: "Importance",
	importFromGroup: "Import from group",
	importFromInvoicingGroup: "Import from invoicing group",
	importZoomLogs: "Import Zoom logs",
	inapplicable: "Inapplicable",
	incommingJointCommittee: "(IN) Joint committee",
	incommingNacebelCode: "(IN) Nacebelcode",
	Independent: "Independent",
	individualHours: "Individual hours",
	individualSession: "Individual session",
	information: "Information",
	InProgress: "In progress",
	InProgressGreen: "In progress",
	InProgressOrange: "In progress",
	InProgressRed: "In porgress",
	InSession: "In session",
	intake: "Intake",
	internal: "Internal",
	interval: "Interval",
	invited: "Invited",
	inviteReminderMessageGroup: "You have added new participants to the group. Don't forget to add them to the desired session and resend the invites!",
	inviteReminderMessageSession: "You have added new participants to the session. Don't forget to resend the invites!",
	Invoiced: "Invoiced",
	invoicing: "Invoicing",
	invoicingDate: "Invoicing date",
	invoicingDateExcel: "Invoicing date e.g.: 22/03/2021",
	invoicingDetails: "Fill in invoice Date & Nr",
	invoicingGroup: "Invoicing group",
	invoicingGroups: "Invoicing groups",
	invoicingMoment: "Invoicing Moment",
	invoicingMoments: "Invoicing Moments",
	invoicingNumber: "Invoicing Number",
	isCollective: "Collective",
	isInfoAndRefferalEmailAllowed: "Information -and referral e-mails allowed",
	isMarketingContactAllowed: "Marketing emails allowed",
	isOnSite: "On site",
	isProjectLevelService: "Project level",
	itemsSelected: "items",
	jobFunction: "Job Function",
	jobFunctions: "Job Functions",
	jointCommittee: "Joint Committee",
	jointCommittees: "Joint Committees",
	knownLanguages: "Known languages",
	language: "Language",
	languages: "Languages",
	lastFunction: "Latest function",
	lastName: "Last name",
	lbc: "LBC",
	LessSatisfied: "Less satisfied",
	letterOfIntent: "Letter of intent",
	linkedIn: "LinkedIn",
	location: "Location",
	locations: "Locations",
	locationSatisfactionQuestion: "What did you think about the location where the outplacement session took place",
	logout: "Log out",
	longDescription: "Long description",
	Low: "Low",
	Male: "M",
	Management: "Management",
	marketingScore: "Marketing score",
	marketingScoreComment: "Marketing score - comments",
	masterdata: "Masterdata",
	maximumParticipantCount: "Maximum participant count",
	Minus45: "45-",
	miscellaneous: "Miscellaneous",
	missingLanguage: "At least 1 recipient has {{language}} as comuunication language, but the '{{field}}' is missing for this language.",
	missingLanguageSingle: "You have selected {{language}} as language, but the '{{field}}' is missing for this language.",
	mobile: "Mobile",
	mobilePhoneNumber: "Mobile phone number",
	month: "month",
	Monthly: "Monthly",
	multiviewcalendar: {
		nextView: "Navigate to next view",
		prevView: "Navigate to previous view"
	},
	municipality: "Municipality",
	myProfile: "My profile",
	nacebelCode: "NACEBEL-code",
	nacebelCodes: "NACEBEL-codes",
	name: "Name",
	nationalities: "Nationalities",
	nationality: "Nationality",
	nationalRegistryNumber: "National registry number",
	Neutral: "Neutral",
	New: "New",
	newCompany: "New company",
	newEmploymentDate: "New employment date",
	newJobFunction: "New jobfunction",
	newYearCard: "New year card",
	nl: "Dutch",
	no: "No",
	no_email_available: "Email not available",
	noGroupForSession: "No group was found to create a session. First select or create a group.",
	noProjectParticipantsFound: "No project participants found.",
	noRecipients: "For this selection, there are no recipients",
	Normal: "Normal",
	NotApplicableWasDigitalSession: "Not Applicable (the session was digital)",
	NotApplicableWasPhysicalSession: "Not Applicable (the session was physical)",
	note: "Note",
	notes: "Notes",
	notFound: "{{entity}} not found",
	noticePeriodEndDate: "Notice ends on",
	NotStarted: "Not started",
	notStartedDate: "Not started date",
	notStartedReason: "Not started reason",
	noZoomSessionId: "Please first fill in a Zoom ID.",
	number: "Number",
	numberOfMonths: "Number of months",
	occupation: "Occupation %",
	of: "of",
	off: "Off",
	OfferSent: "Offer sent",
	ok: "Ok",
	on: "On",
	OnHold: "On hold",
	onHoldDate: "On hold date",
	onHoldReason: "On hold reason",
	opportunities: "Opportunities",
	opportunity: "Opportunity",
	opportunityAttachments: "Opportunity attachments",
	opportunityFollowUpTaskStatus: "Opportunitytask status",
	opportunityOrigins: "Opportunity origin",
	opportunityParticipant: "Opportunity participant",
	opportunityParticipantStatus: "Opportunity participant status",
	opportunityResponsible: "Opportunity responsible",
	opportunityService: "Opportunity service",
	opportunityStatus: "Opportunity status",
	or: "or",
	origin: "Origin",
	otherParticipants: "Other participants",
	outgoingJointCommittee: "(OUT) Joint committee",
	outgoingNacebelCode: "(OUT) Nacebelcode",
	outplacementCertificate: "Outplacement certificate",
	participant: "Participant",
	participantAttachments: "Participant attachments",
	participantDelete: "Participant options",
	participantDeleteInGroups: "Delete participant in all groups of this project?",
	participantDeleteInOtherProjects: "Delete participant in all groups & sessions that are not yet started of other projects?",
	participantDeleteInSessions: "Delete participant in all sessions of this project that are not yet started?",
	participants: "Participants",
	participantStatus: "Participant status",
	partner: "Partner",
	partnerContact: "Partner contact",
	partnerHeat: "Partner heat",
	partners: "Partners",
	Pending: "Pending",
	pending_changes: "Changes",
	permission: "Permission",
	permissions: {
		AbsenceReasonsAdd: "Add",
		AbsenceReasonsDelete: "Delete",
		AbsenceReasonsRead: "Read",
		AbsenceReasonsUpdate: "Update",
		AllowSettingInvoicedStatus: 'Set status to "Invoiced"',
		AllowUpdatingSendSurveyOnClose: 'Update "Send survey on close"',
		AllowAddingSessionWithoutTemplate: "Add session without template",
		BackOfficeTasksRead: "Read",
		CatalogueServicesAdd: "Add",
		CatalogueServicesDelete: "Delete",
		CatalogueServicesRead: "Read",
		CatalogueServicesUpdate: "Update",
		CoachesAdd: "Add",
		CoachesDelete: "Delete",
		CoachesRead: "Read",
		CoachesUpdate: "Update",
		CompaniesAdd: "Add",
		CompaniesDelete: "Delete",
		CompaniesRead: "Read",
		CompaniesUpdate: "Update",
		CompanyGroupsAdd: "Add",
		CompanyGroupsDelete: "Delete",
		CompanyGroupsRead: "Read",
		CompanyGroupsUpdate: "Update",
		ContactsAdd: "Add",
		ContactsDelete: "Delete",
		ContactsRead: "Read",
		ContactsUpdate: "Update",
		ContactTypesAdd: "Add",
		ContactTypesDelete: "Delete",
		ContactTypesRead: "Read",
		ContactTypesUpdate: "Update",
		ContractTypesAdd: "Add",
		ContractTypesDelete: "Delete",
		ContractTypesRead: "Read",
		ContractTypesUpdate: "Update",
		DegreesAdd: "Add",
		DegreesDelete: "Delete",
		DegreesRead: "Read",
		DegreesUpdate: "Update",
		DocumentTemplatesAdd: "Add",
		DocumentTemplatesDelete: "Delete",
		DocumentTemplatesRead: "Read",
		DocumentTemplatesUpdate: "Update",
		EmailTemplatesAdd: "Add",
		EmailTemplatesDelete: "Delete",
		EmailTemplatesRead: "Read",
		EmailTemplatesUpdate: "Update",
		ErrorLogRead: "Read",
		ExpertisesAdd: "Add",
		ExpertisesDelete: "Delete",
		ExpertisesRead: "Read",
		ExpertisesUpdate: "Update",
		FacilitiesAdd: "Add",
		FacilitiesDelete: "Delete",
		FacilitiesRead: "Read",
		FacilitiesUpdate: "Update",
		GovernmentServicesAdd: "Add",
		GovernmentServicesDelete: "Delete",
		GovernmentServicesRead: "Read",
		GovernmentServicesUpdate: "Update",
		GroupsAdministrator: "Groups administrator",
		GroupsAll: "All groups",
		GroupsRead: "Read",
		GroupsUpdate: "Update",
		invoiceServicesAll: "All services",
		invoiceServicesDelete: "Delete",
		invoiceServicesRead: "Read",
		invoiceServicesUpdate: "Update",
		InvoicingAccess: "Access",
		InvoicingGroupsAdd: "Add",
		InvoicingGroupsDelete: "Delete",
		InvoicingGroupsRead: "Read",
		InvoicingGroupsUpdate: "Update",
		InvoicingMomentsAdd: "Add",
		InvoicingMomentsDelete: "Delete",
		InvoicingMomentsRead: "Read",
		InvoicingMomentsUpdate: "Update",
		JobFunctionsAdd: "Add",
		JobFunctionsDelete: "Delete",
		JobFunctionsRead: "Read",
		JobFunctionsUpdate: "Update",
		JointCommitteesAdd: "Add",
		JointCommitteesDelete: "Delete",
		JointCommitteesRead: "Read",
		JointCommitteesUpdate: "Update",
		LanguagesAdd: "Add",
		LanguagesDelete: "Delete",
		LanguagesRead: "Read",
		LanguagesUpdate: "Update",
		LocationsAdd: "Add",
		LocationsDelete: "Delete",
		LocationsRead: "Read",
		LocationsUpdate: "Update",
		NacebelCodesAdd: "Add",
		NacebelCodesDelete: "Delete",
		NacebelCodesRead: "Read",
		NacebelCodesUpdate: "Update",
		NationalitiesAdd: "Add",
		NationalitiesDelete: "Delete",
		NationalitiesRead: "Read",
		NationalitiesUpdate: "Update",
		OpportunitiesAdd: "Add",
		OpportunitiesAll: "All opportunities",
		OpportunitiesDelete: "Delete",
		OpportunitiesRead: "Read",
		OpportunitiesUpdate: "Update",
		OpportunityOriginsAdd: "Add",
		OpportunityOriginsDelete: "Delete",
		OpportunityOriginsRead: "Read",
		OpportunityOriginsUpdate: "Update",
		ParticipantsAdd: "Add",
		ParticipantsDelete: "Delete",
		ParticipantsRead: "Read",
		ParticipantsUpdate: "Update",
		PartnersAdd: "Add",
		PartnersDelete: "Delete",
		PartnersRead: "Read",
		PartnersUpdate: "Update",
		PlanningDisplay: "Display",
		PrivateContactInformationRead: "Read",
		PrivateContactInformationWrite: "Write",
		ProjectGroupsAdd: "Add",
		ProjectGroupsDelete: "Delete",
		ProjectGroupsRead: "Read",
		ProjectGroupsUpdate: "Update",
		ProjectParticipantsAdministrator: "Participants administrator",
		ProjectParticipantsAll: "All participants",
		ProjectParticipantsRead: "Read",
		ProjectParticipantsUpdate: "Update",
		ProjectsAdd: "Add",
		ProjectsAll: "All projects",
		ProjectsDelete: "Delete",
		ProjectsRead: "Read",
		ProjectsUpdate: "Update",
		ProvincesAdd: "Add",
		ProvincesDelete: "Delete",
		ProvincesRead: "Read",
		ProvincesUpdate: "Update",
		RolesAdd: "Add",
		RolesDelete: "Delete",
		RolesRead: "Read",
		RolesUpdate: "Update",
		SectorsAdd: "Add",
		SectorsDelete: "Delete",
		SectorsRead: "Read",
		SectorsUpdate: "Update",
		ServiceTypesAdd: "Add",
		ServiceTypesDelete: "Delete",
		ServiceTypesRead: "Read",
		ServiceTypesUpdate: "Update",
		SessionRegistrationAppAccess: "Access",
		SessionsAdministrator: "Sessions administrator",
		SessionsAll: "All sessions",
		SessionsDelete: "Delete",
		SessionsRead: "Read",
		SessionsUpdate: "Update",
		SessionTemplatesAdd: "Add",
		SessionTemplatesDelete: "Delete",
		SessionTemplatesRead: "Read",
		SessionTemplatesUpdate: "Update",
		SettingsRead: "Read",
		SettingsUpdate: "Update",
		StatutesAdd: "Add",
		StatutesDelete: "Delete",
		StatutesRead: "Read",
		StatutesUpdate: "Update",
		TasksAll: "All tasks",
		TaskSubjectsAdd: "Add",
		TaskSubjectsDelete: "Delete",
		TaskSubjectsRead: "Read",
		TaskSubjectsUpdate: "Update",
		TopicsAdd: "Add",
		TopicsDelete: "Delete",
		TopicsRead: "Read",
		TopicsUpdate: "Update",
		UsersAdd: "Add",
		UsersDelete: "Delete",
		UsersRead: "Read",
		UsersUpdate: "Update",
		SessionsMinimumBillingHours: "Minimum billing hours",
		SessionsExtraBillingHours: "Extra billing hours",
		SessionTemplatesMinimumBillingHours: "Minimum billing hours",
		SessionTemplatesExtraBillingHours: "Extra billing hours"
	},
	personInfo: "Person info",
	phoneNumber: "Phone number",
	physicalInteractionSatisfactionQuestion: "What did you think about the interaction of the physical outplacement guidance",
	plan: "Plan",
	Planned: "Planned",
	PlannedGreen: "Planned",
	PlannedOrange: "Planned",
	PlannedRed: "Planned",
	plannedStart: "Planned start",
	plannedStop: "Planned stop",
	planning: {
		currentWeek: "Current week",
		friday: "Friday",
		monday: "Monday",
		nextWeek: "Next week",
		no_information: "No information",
		occupation_message: "Occupied for {{percentage}} %",
		openSession: "Open session",
		planning: "Planning",
		prevWeek: "Previous week",
		saturday: "Saturday",
		thursday: "Thursday",
		tuesday: "Tuesday",
		wednesday: "Wednesday"
	},
	planSessions: "Plan sessions",
	Plus45: "45+",
	postalCode: "Postalcode",
	preferedFutureFunction: "Prefered future function",
	present: "Present",
	price: "Price €",
	priority: "Priority",
	priorityDown: "Move down priority",
	priorityUp: "Move up priority",
	privacy: "Privacy",
	privateContactInformation: "Private contact information",
	privateEmailAddress: "Private email",
	privateMobilePhoneNumber: "Private mobile phone",
	processed: "Processed",
	processRequestInitiated: "The request to process the excel was successfull.",
	project: "Project",
	projectFollowUpTaskStatus: "Projecttask status",
	projectGroup: "Project group",
	projectGroups: "Project groups",
	projectName: "Project name",
	projectOrParticipant: "Kind of serviceline",
	projectParticipant: "Project participant",
	projectParticipantFollowUpTaskStatus: "Project participant taskstatus",
	projectParticipantResponsible: "Project participant responsible",
	projectParticipantService: "Project participant service",
	projectParticipantStatus: "Project participant status",
	projectResponsible: "Project responsible",
	projects: "Projects",
	projectService: "Project service",
	projectServices: "Project services",
	projectServiceStatus: "Project service status",
	projectShow: "Show project",
	projectStatus: "Project status",
	promotionalGift: "Promotional gift",
	province: "Province",
	provinces: "Provinces",
	publicTender: "Public tender",
	putNameOnInvoice: "Show name on invoice",
	ratingQuestion: "Which rating do you give the finished outplacement session",
	ratingReasonQuestion: "Why",
	recipientEmailNotAvailable: "Email of the recipient is not available",
	reclamation: "Reclamation",
	Rejected: "Rejected",
	rejectionReason: "Reason of rejection",
	relatedTo: "Related to",
	remarks: "Remarks",
	reminder: "Reminder",
	remove: "Remove",
	repetition: "Number of repetitions",
	replacement: "Replacement",
	reportableGovernmentService: "Reportable government service",
	requestParticipantsInfo: "Request participants info",
	requests: "Requests",
	reset: "Reset",
	resources: "Resources",
	responsible: "Responsible",
	responsibleFirstName: "Contact - First name",
	responsibleLastName: "Contact - Last name",
	role: "Role",
	roles: "Roles",
	room: "Room",
	salesPrice: "Sales price",
	satisfactionScore: "Satisfaction score",
	Satisfied: "Satisfied",
	save: "Save",
	saveAndClose: "Save and close",
	scheduler: {
		allDay: "All day",
		showFullDay: "Show full day"
	},
	searchAllProjects: "Search in all projects",
	sector: "Sector",
	sectors: "Sectors",
	security: "Security",
	select: "Select",
	select_at_least_one: "Please select at least one {{entity}}.",
	selectAll: "Select All",
	selectColumns: "Select columns",
	selectExistingParticipantProjectChangesWillBeSaved: "Select a participant of this session. Upon saving, all current changes on this session will be saved as well.",
	selectionOfParticipants: "Select participants",
	selectRecipients: "Please select recipients",
	send: "Send",
	sendEmail: "Send email",
	sendInvitation: "Send invitation",
	sendSurveyOnClose: "Send survey on close",
	sendTo: "Send to",
	sendUpdateInvitation: "Send update invitation",
	seniorityPreviousEmployment: "Seniority at previous employment",
	Servant: "Servant",
	service: "Service",
	serviceCode: "Service code",
	services: "Services",
	serviceShow: "Show service",
	serviceStatus: "Service status",
	serviceType: "Service Type",
	serviceTypes: "Service Types",
	session: "Session",
	Session: "Session",
	sessionFeedback: "Session Feedback",
	sessionFollowUpTaskStatus: "Sessiontask status",
	sessionName: "Session name",
	sessionNotInApp: "Session is not present in the web application",
	sessionNotInOutlook: "Session is not present in outlook",
	sessionOccupationOrange: "Session occupation % - Orange",
	sessionOccupationRed: "Session occupation % - Red",
	sessionParticipant: "Session participant",
	sessionRegistrationApp: "Session registration app",
	sessionResponsible: "Session responsible",
	sessions: "Sessions",
	sessionStatus: "Session status",
	sessionTemplate: "Session template",
	sessionTemplateFollowUpTask: "Session template task",
	sessionTemplates: "Session templates",
	settings: "Settings",
	shortDescription: "Short Description",
	show: "Show",
	showAllGroups: "Show all groups",
	showAllOpportunities: "Show all opportunities",
	showAllParticipants: "Show all participants",
	showAllProjects: "Show all projects",
	showAllSessions: "Show all sessions",
	showAllTasks: "Show all tasks",
	showColumnChooser: "Show column chooser",
	showFeedback: "Show Feedback",
	showLBC1: 'Show "LBC 1"',
	showLBC2: 'Show "LBC 2"',
	showLBCPay: 'Show "Loopbaancheque - Betalend"',
	signature: "Signature",
	spread: "Spread",
	start: "Start",
	startDate: "Start date",
	status: "Status",
	statute: "Statute",
	statutes: "Statutes",
	stopDate: "Stopped date",
	Stopped: "Stopped",
	stopReason: "Stopped reason",
	street: "Street",
	subject: "Subject",
	subjectDe: "Subject DE",
	subjectEn: "Subject EN",
	subjectFr: "Subject FR",
	subjectNl: "Subject NL",
	subtotal: "Subtotal",
	subType: "Subtype",
	success: "Success",
	support: "Support",
	surveyLink: "Survey Link",
	task: "Task",
	taskAssignee: "Task responsible",
	taskAttachments: "Task attachments",
	tasks: "Tasks",
	taskSubject: "Task subject",
	taskSubjects: "Task subjects",
	template: "Template",
	theseAreTheServerErrorMessages: "These are the server validation errors:",
	thresholds: "Thresholds",
	timepicker: {
		cancel: "Cancel",
		now: "NOW",
		selectNow: "Select Now",
		set: "Set",
		toggleClock: "Toggle Clock",
		toggleTimeSelector: "Toggle TimeSelector"
	},
	title: "Title",
	to: "To",
	toBeDetirmined: "Te be detirmined",
	ToBeInvoiced: "To be invoiced",
	topic: "Topic",
	topics: "Topics",
	totalAmount: "Total amount €",
	totalHours: "Total",
	type: "Type",
	Undefined: "X",
	uniqueKey: {
		IX_AbsenceReasons_Name: "name",
		IX_CatalogueServices_Code: "code",
		IX_Companies_VatNumber: "VAT number",
		IX_CompanyGroups_Name: "name",
		IX_Contact_Email: "email",
		IX_ContactTypes_Name: "name",
		IX_ContractTypes_Name: "name",
		IX_Degrees_Name: "name",
		IX_DocumentTemplates_Name: "name",
		IX_EmailTemplates_Name: "name",
		IX_Expertises_Name: "name",
		IX_Facilities_Name: "name",
		IX_InvoicingMoments_Name: "name",
		IX_JobFunctions_Name: "name",
		IX_JointCommittees_Name: "name",
		IX_Languages_Name: "name",
		IX_NacebelCodes_Name: "name",
		IX_Nationalities_Name: "name",
		IX_Partners_VatNumber: "VAT number",
		IX_Roles_Name: "name",
		IX_Sectors_Name: "name",
		IX_ServiceTypes_Name: "name",
		IX_sessions: "These participants are still in these sessions: ",
		IX_SessionTemplateFollowUpTasks_Subject_SessionTemplateId: "this subject within this template",
		IX_Statutes_Name: "name",
		IX_TaskSubjects_Name: "name",
		IX_Topics_Name: "name",
		IX_Users_Email: "email"
	},
	Unsatisfied: "Unsatisfied",
	upload: {
		cancel: "Cancel",
		clearSelectedFiles: "Clear",
		dropFilesHere: "Drop files here to upload",
		files: "files",
		headerStatusUploaded: "Done",
		headerStatusUploading: "Uploading...",
		invalidFileExtension: "File type not allowed.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "File size too large.",
		invalidMinFileSize: "File size too small.",
		remove: "Remove",
		retry: "Retry",
		select: "Select files...",
		total: "Total",
		uploadSelectedFiles: "Upload"
	},
	uploadExcel: "Upload Excel",
	uploadParticipantsList: "Upload participants",
	usedHours: "Used",
	user: "User",
	users: "Users",
	vatNumber: "VAT number",
	vatNumberFormat: "Wrong format",
	VerySatisfied: "Very Satisfied",
	VeryUnsatisfied: "Very Unsatisfied",
	warning_empty_emails: "Warning: Not all participants have an email address.",
	Weekly: "Weekly",
	welcome_message: "Welcome ",
	work: "Work",
	Worker: "Worker",
	workingExperiences: "Working experiences",
	year: "year",
	yes: "Yes",
	yourprofile: "Your profile",
	zoomSessionId: "Zoom session ID",
	errorDownloadingAttachments: "There was een error downloading the attachments.",
	noAttachmentsToDownload: "There are no attachments to download.",
	noAttachmentsSelected: "No attachments selected",
	downloadAttachments: "Download attachments",
	accessToTraP: "Access to TraP",
	startDateIV1: "Start date IV1",
	startDateIV2: "Start date IV2",
	countAsPresent: "Count as present",
	minimumBillingHours: "Minimum billing hours",
	extraBillingHours: "Extra billing hours",
	showOnlyToBeInvoiced: "Show only to be invoiced",
	calculatedHours: "Total calculated hours"
};

export default translationsEN;
