import ITranslation from "./translation";
const translationFR: ITranslation = {
	absenceReason: "Motif d'absence",
	absenceReasons: "Motifs d'absence",
	absent: "Absent",
	accepted: "Accepté",
	account: "Compte comptable",
	actions: "Actions",
	active: "Actif",
	activeProvinces: "Provinces actives",
	actualStart: "Début réel",
	actualStop: "Arrêt réel",
	add: "Ajouter",
	add_at_least_one: "Ajouter au moins 1 {{entity}}",
	addIntake: "Ajouter intake",
	Aftercare: "Suivi",
	ageClass: "Classe d'âge",
	allParticipants: "Tous les participants",
	ambitions: "Ambitions",
	amount: "Nombre",
	and: "Et",
	annaFromTravvant: "Anna de Travvant",
	anomaly: "Anomalie",
	Approved: "Approuvé",
	ask_close: "Il y a des modifications non enregistrées. Voulez-vous vraiment fermer cette fenêtre?",
	ask_save: "Il y a encore des modifications non enregistrées. Voulez-vous les enregistrer? ",
	assignee: "Responsable",
	attachment: "Annexe",
	attachments: "Annexes",
	attachmentsTooLarge: "Les annexes sélectionnées ensemble font plus de 10 Mo. Veuillez sélectionner moins.",
	attachTo: "Annexe pour",
	attendance: "Présence",
	backOfficeTasks: "Tâches",
	bcc: "Cci",
	begin: "Commencer",
	birthdayCard: "Carte d'anniversaire",
	body: "Corps",
	bodyDe: "Corps DE",
	bodyEn: "Corps EN",
	bodyFr: "Corps FR",
	bodyNl: "Corps NL",
	building: "Bâtiment",
	calendar: {
		today: "Aujourd'hui"
	},
	cancel: "Annuler",
	cancelInvitation: "Annuler invitation",
	Cancelled: "Annulé",
	catalogue: "Catalogue",
	catalogueService: "Catalogue service",
	catering: "Catering",
	cateringPrice: "Catering prix",
	cc: "Cc",
	chanceOfSucces: "Taux de réussite",
	changeStatusError: "Ã‰chec, veuillez réessayer",
	changeStatusSuccess: "Statut changé en facturer",
	changeStatusToInvoice: "Changer le statut en facture",
	checkList: "Check-list",
	chooseFilter: "Choisir le filtre",
	close: "Fermer",
	Closed: "Fermé",
	coach: "Coach",
	coaches: "Coachs",
	coachSatisfactionQuestion: "Dans quelle mesure êtes-vous satisfait de la façon dont votre coach a dirigé la session d'outplacement",
	code: "Code",
	collectiveHours: "Heures collectives",
	commissionPercentage: "Taux de commission",
	communicationLanguage: "Langue de communication",
	companies: "Entreprises",
	company: "Entreprise",
	companyContact: "Contact d'entreprise",
	companyGroup: "Groupe d'entreprises",
	companyGroups: "Groupes d'entreprises",
	companyHeat: "Degré d'importance de l'entreprise",
	Completed: "Terminé",
	confirm_cancel_invitation: "Voulez-vous vraiment que cet invitation soit annuler?",
	confirm_content: "Voulez-vous vraiment que cet enregistrement soit {{action}}?",
	confirm_currentuser_activechange: "Vous désactivez votre propre compte. Vous ne saurez plus vous connecter si vous enregistrez. Voulez-vous continuer?",
	confirm_currentuser_rolechange: "Vous modifiez les rôles de votre propre compte. Vous ne pourrez peut-être plus annuler cette action. Souhaitez-vous continuer?",
	confirm_file_remove: "Voulez-vous vraiment supprimer ce fichier?",
	confirm_message_all_filled_in: "Voulez-vous vraiment d'avoir tout saisi correctement?",
	confirm_overwrite_field: "Voulez-vous remplacer '{{field}}'?",
	confirm_overwrite_session_template: "Voulez-vous remplacer les données de session par celles du modèle? ",
	confirm_reset: "Voulez-vous vraiment restaurer les paramètres?",
	confirm_role_update_current_user: "Vous modifiez les autorisations d'un rôle associé à votre compte. Vous ne pourrez peut-être plus annuler cette action. Souhaitez-vous continuer?",
	confirm_title: "Confirmation {{action}}",
	confirmLeave: "Les données n'ont pas été enregistrées, voulez-vous quitter cet écran?",
	contact: "Contact",
	contacts: "Contacts",
	contactType: "Type de contact",
	contactTypes: "Types de contact",
	content: "Contenu",
	contractSent: "Contrat envoyé",
	contractSentToGovernment: "Contrat envoyé au gouvernement",
	contractSignedReceived: "Contrat signé reçu",
	contractType: "Type de contrat",
	contractTypes: "Types de contrats",
	copy: "Copy",
	copyProject: "Copy projet",
	copyProjectFromProject: "Copy projet",
	copyServices: "Copy services",
	cost: "Montant en €",
	country: "Pays",
	create: "Créer",
	createdDate: "Date de création",
	createDocument: "Créer un document",
	createdOn: "Créé le",
	createFromContact: "Créer à partir de contact",
	createNew: "Ce <1/> est introuvable.<3/>Voulez-vous créer un nouveau?",
	createOpportunityFromProject: "Créer une opportunité d'un projet",
	createProject: "Créer un projet",
	createProjectFromOpportunity: "Créer un projet d'une opportunité",
	createProjectSucces: "Projet créé avec succès",
	creator: "Créé par",
	customer: "Client",
	customerPONumber: "Numéro de bon de commande du client",
	customerShow: "Afficher le client",
	customRecipient: "Récepteur personnalisé",
	Daily: "Quotidien",
	date: "Date",
	dateinput: {
		decrement: "Diminuer la valeur",
		increment: "Augmenter la valeur"
	},
	dateOfBirth: "Date de naissance",
	datepicker: {
		toggleCalendar: "Basculer le calendrier"
	},
	datetimepicker: {
		cancel: "Annuler",
		date: "Date",
		set: "Choisir",
		time: "Heure",
		toggleDateTimeSelector: "Ouvrir/fermer"
	},
	day: "Jour",
	Deelnemer: "Deelnemer",
	degree: "Diplôme",
	degrees: "Diplômes",
	delete: "Supprimer",
	description: "Description",
	details: "Détails",
	digitalInteractionSatisfactionQuestion: "Qu'avez-vous pensé de l'interaction dans l'orientation de l'outplacement numérique",
	displayedUsers: "Filtrer les utilisateurs affichés",
	distributeParticipants: "Diviser les participants",
	document: "Document",
	documentName: "Nom du document",
	documentNameDe: "Nom du document DE",
	documentNameEn: "Nom du document EN",
	documentNameFr: "Nom du document FR",
	documentNameNl: "Nom du document NL",
	documentRequestedSuccessfully: "Demande de document(s) réussie",
	documentTemplate: "Modèle de document",
	documentTemplates: "Modèles de documents",
	DoNotInvoice: "Ne pas facturer",
	downloadParticipantsTemplate: "Télécharger le modèle des participants",
	downloadProjectParticipants: "Télécharger les participants du projet",
	dueDate: "Date d'échéance",
	Dutch: "Néerlandais",
	edit: "Modifier",
	editRelatedEntity: "Modifier d'entité liée",
	education: "Formation",
	email: "E-mail",
	emailRequestedSuccessfully: "Demande d'e-mail(s) réussie",
	emails: "E-mails",
	emailTemplate: "Modèle d'e-mail",
	emailTemplates: "Modèles d'e-mails",
	employmentConditions: "Préférence conditions de travail",
	employmentUnitEnrollDate: "Date d'inscription",
	employmentUnitLeaveDate: "Date de désinscription",
	en: "Anglais",
	end: "Fin",
	English: "Anglais",
	environmentConditions: "Conditions environnement",
	error: "Erreur",
	errorLog: "Journal des erreurs",
	errorLogId: "Journal des erreurs identifiant",
	errors: {
		error_argumentnull: "Il y avait un {{argument}} vide sur le serveur.",
		error_connection: "Impossible de se connecter à un service externe. ",
		error_constraintfailure: "Une règle de validation de base de données a échoué. ",
		error_dbconcurrency: "Cet enregistrement a été modifié ou supprimé par quelqu'un d'autre entre-temps. Merci de bien vouloir renouveler la page.",
		error_deleteFKConstraintStillUsed: "Vous essayez d'effacer une entité qui est présente dans d'autres entités: ",
		error_duplicate: "Un enregistrement à {{uniqueKey}} existe déjà.",
		error_entityused: "Cette entité est encore utilisée au sein de l'application. Si vous ne souhaitez plus la voir, veuillez la désactiver.",
		error_general: "Une erreur s'est produite sur le serveur.",
		error_idfilledin: "L'ID ne peut être saisi lors de l'ajout d'une entité.",
		error_idnotfilledin: "L'ID doit être communiqué lorsque vous essayez de supprimer quelque chose.",
		error_nofile: "Aucun fichier à télécharger",
		error_notAllServicesAreInvoiced: "Veuillez vous assurer que tous les services sont facturés avant de terminer un projet.",
		error_routeiddoesnotmatch: "L'ID de l'itinéraire URL ne correspond pas à l'ID de l'entité.",
		error_server_validation: "Erreur de validation du serveur",
		error_sqlconnection: "Impossible de se connecter à la base de données",
		error_sqlgeneral: "Une erreur de base de données s'est produite.",
		error_validation: "Il y a une erreur de validation.",
		error_vatnumber: "Une erreur s'est produite quant au numéro de TVA spécifié",
		forbidden: "Vous n'avez pas de droits sur cette partie du serveur.",
		not_authenticated: "Vous n'êtes pas connecté au serveur.",
		not_found: "Le serveur n'a pas trouvé de résultat.",
		server_error: "Le serveur a renvoyé une réponse '{{statuscode}}'"
	},
	example: "Exemple",
	excelExport: "Exporter les champs sélectionnés vers Excel",
	excelImport: "Importer l'Excel ",
	excelImportRequest: "La demande d'ajustement des données de facturation a réussi",
	Executive: "Cadre",
	expertise: "Expertise",
	expertises: "Expertises",
	extra: "Extra",
	facilities: "Facilités",
	facility: "Facilité",
	Female: "F",
	file: "Fichier",
	fill_in_correct_date: "Veuillez indiquer une date postérieure à 1900 pour le champ '{{field}}'",
	fill_in_correct_format: "Veuillez saisir une valeur correcte pour le champ '{{field}}'. Par ex.: '{{example}}'.",
	fill_in_correct_planned_hours: "Veuillez saisir une date et une heure correctes de manière à ce que '{{begin}}' précède '{{end}}'. ",
	fill_in_required_field: "Veuillez remplir le champ obligatoire '{{field}}'.",
	Finished: "Terminé",
	fireOrQuitDate: "Date de licenciement",
	firstName: "Prénom",
	firstSessionDate: "Premier session",
	followUpTaskStatus: "Statut de la tâche",
	for: "Pour",
	Forecast: "Prévision",
	forecastCost: "Montant prévisionnel en â‚¬",
	fr: "Français",
	French: "Français",
	from: "Envoyé par",
	fullName: "Nom complet",
	functionConditions: "Conditions contenu de la fonction",
	functionPreviousEmployment: "Fonction chez ancien employeur",
	gender: "Sexe",
	general: "Général",
	German: "Allemand",
	governmentService: "Service gouvernemental",
	governmentServices: "Services gouvernementaux",
	grid: {
		filterAfterOperator: "Est après",
		filterAfterOrEqualOperator: "Est après ou égal à",
		filterAndLogic: "Et",
		filterBeforeOperator: "Est avant",
		filterBeforeOrEqualOperator: "Est avant ou égal à",
		filterBooleanAll: "(Tout)",
		filterBooleanIsNull: "Indéfini",
		filterClearButton: "Vider",
		filterContainsOperator: "Contient",
		filterEndsWithOperator: "Se termine par",
		filterEqOperator: "Est égal à",
		filterGteOperator: "Est supérieur ou égal à",
		filterGtOperator: "Est supérieur à",
		filterIsEmptyOperator: "Est vide",
		filterIsFalse: "Non",
		filterIsNotEmptyOperator: "N'est pas vide",
		filterIsNotNullOperator: "N'est pas nul",
		filterIsNullOperator: "Est nul",
		filterIsTrue: "Oui",
		filterLteOperator: "Est inférieur ou égal à",
		filterLtOperator: "Est inférieur à",
		filterNotContainsOperator: "Ne contient pas",
		filterNotEqOperator: "N'est pas égal à",
		filterOrLogic: "Ou",
		filterStartsWithOperator: "Commence par",
		filterSubmitButton: "Filtre",
		filterTitle: "Filtre",
		groupPanelEmpty: "Faites glisser une colonne ici pour pouvoir regrouper",
		noRecords: "Pas de données disponibles",
		pagerFirstPage: "Aller à la première page",
		pagerInfo: "{0} - {1} sur {2} éléments",
		pagerItemsPerPage: "Eléments par page",
		pagerLastPage: "Aller à la dernière page",
		pagerNextPage: "Aller à la page suivante",
		pagerOf: "De",
		pagerPage: "Page",
		pagerPreviousPage: "Aller à la page précédente",
		sortAscending: "Trier par ordre croissant",
		sortDescending: "Trier par ordre décroissant"
	},
	group: "Groupe",
	groupCode: "Code de groupe",
	groupFollowUpTaskStatus: "Statut de la tâche de groupe",
	groupInfo: "Informations sur le groupe",
	groupOccupationOrange: "Occupation de groupe % - Orange",
	groupOccupationRed: "Occupation de groupe % - Rouge",
	groupResponsible: "Responsable de groupe",
	groups: "Groupes",
	heat: "Importance",
	help: "Aide",
	hideColumn: "Masquer la colonne",
	High: "Haut",
	highestDegree: "Diplôme le plus élevé",
	history: "Histoire",
	home: "Accueil",
	import: "Importer",
	importance: "Urgence",
	importFromGroup: "Importer du groupe",
	importFromInvoicingGroup: "importFromInvoicingGroup",
	importZoomLogs: "Importer logging Zoom",
	inapplicable: "Ne s'applique pas",
	incommingJointCommittee: "(IN) comité paritaire",
	incommingNacebelCode: "(IN) code Nacebel",
	Independent: "Indépendant",
	individualHours: "Heures individuelles",
	individualSession: "Session individuelle",
	information: "Information",
	InProgress: "En traitement",
	InProgressGreen: "En traitement",
	InProgressOrange: "En traitement",
	InProgressRed: "En traitement",
	InSession: "En session",
	intake: "Intake",
	internal: "Interne",
	interval: "L'intervalle",
	invited: "Invité",
	inviteReminderMessageGroup: "Vous avez ajouté de nouveaux participants au groupe. N'oubliez pas de les ajouter à la session souhaitée et de renvoyer les invitations!",
	inviteReminderMessageSession: "Vous avez ajouté de nouveaux participants à la session. N'oubliez pas de renvoyer les invitations!",
	Invoiced: "Facturé",
	invoicing: "Facturation",
	invoicingDate: "La date de facturation",
	invoicingDateExcel: "La date de facturation vb: 22/03/2021",
	invoicingDetails: "Remplissez le numéro et la date de facture",
	invoicingGroup: "Groupe de facturation",
	invoicingGroups: "Groupes de facturation",
	invoicingMoment: "Moment de facturation",
	invoicingMoments: "Moments de facturation",
	invoicingNumber: "Le numéro de facture",
	isCollective: "Collectif",
	isInfoAndRefferalEmailAllowed: "Courriels d'information et d'orientation autorisés",
	isMarketingContactAllowed: "E-mails marketing autorisés",
	isOnSite: "Sur site",
	isProjectLevelService: "Niveau de projet",
	itemsSelected: "éléments",
	jobFunction: "Fonction d'emploi",
	jobFunctions: "Fonctions d'emploi",
	jointCommittee: "Comité paritaire ",
	jointCommittees: "Comités paritaires",
	knownLanguages: "MaÃ®trise de langues",
	language: "Langue",
	languages: "Langues",
	lastFunction: "Dernière fonction exécutée",
	lastName: "Nom de famille",
	lbc: "Chèques de carrière",
	LessSatisfied: "Moins satisfait",
	letterOfIntent: "Avis d'intention",
	linkedIn: "LinkedIn",
	location: "Lieu",
	locations: "Lieux",
	locationSatisfactionQuestion: "Qu'avez-vous pensé de l'endroit où s'est déroulée la session d'outplacement",
	logout: "Se déconnecter",
	longDescription: "Description détaillée",
	Low: "Bas",
	Male: "M",
	Management: "Direction",
	marketingScore: "Score marketing",
	marketingScoreComment: "Score marketing - remarques",
	masterdata: "Données de base",
	maximumParticipantCount: "Nombre maximum de participants",
	Minus45: "45-",
	miscellaneous: "Divers",
	missingLanguage: "Au moins 1 destinataire a comme langue de communication {{language}}, mais le champ '{{field}}' n'a pas été complété.",
	missingLanguageSingle: "Vous avez indiqué la langue {{language}}, mais le champ '{{field}}' n'a pas été complété.",
	mobile: "Mobile",
	mobilePhoneNumber: "Numéro de GSM",
	month: "Mois",
	Monthly: "Mensuel",
	multiviewcalendar: {
		nextView: "Accéder à la vue suivante",
		prevView: "Accéder à la vue précédente"
	},
	municipality: "Commune",
	myProfile: "Mon profil",
	nacebelCode: "Code Nacebel",
	nacebelCodes: "Codes Nacebel",
	name: "Nom",
	nationalities: "Nationalités",
	nationality: "Nationalité",
	nationalRegistryNumber: "Numéro de registre national",
	Neutral: "Neutre",
	New: "Nouveau",
	newCompany: "Nouvelle entreprise",
	newEmploymentDate: "Nouvelle date de départ",
	newJobFunction: "Nouvelle fonction d'emploi",
	newYearCard: "Carte de nouvel an",
	nl: "Néerlandais",
	no: "Non",
	no_email_available: "E-mail non disponible",
	noGroupForSession: "Aucun groupe n'a été trouvé pour créer une session. Sélectionnez ou créez d'abord un groupe.",
	noProjectParticipantsFound: "Aucun participant de projet trouvé",
	noRecipients: "Pour cette sélection, il n'y a pas de destinataires.",
	Normal: "Normal",
	NotApplicableWasDigitalSession: "Non applicable (la session est devenue numérique)",
	NotApplicableWasPhysicalSession: "Non applicable (la session s'est poursuivie physiquement)",
	note: "Note",
	notes: "Notes",
	notFound: "{{entity}} introuvable",
	noticePeriodEndDate: "Préavis à prester jusqu'au",
	NotStarted: "Pas commencé",
	notStartedDate: "Date de pas commencé",
	notStartedReason: "Motif de pas commencé",
	noZoomSessionId: "Veuillez d'abord saisir l'ID Zoom",
	number: "Numéro",
	numberOfMonths: "Nombre de mois",
	occupation: "Occupation %",
	of: "de",
	off: "Off",
	OfferSent: "Offerte envoyée",
	ok: "Ok",
	on: "On",
	OnHold: "En attente",
	onHoldDate: "Date d'en attente",
	onHoldReason: "Motif d'en attente",
	opportunities: "Opportunités",
	opportunity: "Opportunité",
	opportunityAttachments: "Annexes à opportunité",
	opportunityFollowUpTaskStatus: "Statut de la tâche de l'opportunité",
	opportunityOrigins: "Origine de l'opportunité",
	opportunityParticipant: "Participant à l'opportunité",
	opportunityParticipantStatus: "Statut du participant à l'opportunité",
	opportunityResponsible: "Responsable de l'opportunité",
	opportunityService: "Service d'opportunité",
	opportunityStatus: "Statut de l'opportunité",
	or: "Ou",
	origin: "Origine",
	otherParticipants: "Autres participants",
	outgoingJointCommittee: "(OUT) Comité paritaire",
	outgoingNacebelCode: "(OUT) Code nacebel",
	outplacementCertificate: "Attestation outplacement",
	participant: "Participant",
	participantAttachments: "Annexes au participant",
	participantDelete: "Options des participants",
	participantDeleteInGroups: "Supprimer le participant de tous les groupes de ce projet?",
	participantDeleteInOtherProjects: "Supprimer le participant de tous des groupes & des sessions qui n'ont pas encore commencé d'autres projets?",
	participantDeleteInSessions: "Supprimer le participant de tous les sessions de ce projet qui n'ont pas encore commencé?",
	participants: "Participants",
	participantStatus: "Le statut de participant ",
	partner: "Partenaire",
	partnerContact: "Contact partenaire ",
	partnerHeat: "Importance du partenaire",
	partners: "Partenaires",
	Pending: "En attente",
	pending_changes: "Modifications",
	permission: "Autorisation",
	permissions: {
		AbsenceReasonsAdd: "Ajouter",
		AbsenceReasonsDelete: "Supprimer",
		AbsenceReasonsRead: "Lire",
		AbsenceReasonsUpdate: "Modifier",
		AllowSettingInvoicedStatus: 'Autoriser le statut "Facturé"',
		AllowUpdatingSendSurveyOnClose: 'Mise à jour "Envoyer l\'enquête à la fermeture"',
		AllowAddingSessionWithoutTemplate: "Ajouter une session sans modèle",
		BackOfficeTasksRead: "Lire",
		CatalogueServicesAdd: "Ajouter",
		CatalogueServicesDelete: "Supprimer",
		CatalogueServicesRead: "Lire",
		CatalogueServicesUpdate: "Modifier",
		CoachesAdd: "Ajouter",
		CoachesDelete: "Supprimer",
		CoachesRead: "Lire",
		CoachesUpdate: "Modifier",
		CompaniesAdd: "Ajouter",
		CompaniesDelete: "Supprimer",
		CompaniesRead: "Lire",
		CompaniesUpdate: "Modifier",
		CompanyGroupsAdd: "Ajouter",
		CompanyGroupsDelete: "Supprimer",
		CompanyGroupsRead: "Lire",
		CompanyGroupsUpdate: "Modifier",
		ContactsAdd: "Ajouter",
		ContactsDelete: "Supprimer",
		ContactsRead: "Lire",
		ContactsUpdate: "Modifier",
		ContactTypesAdd: "Ajouter",
		ContactTypesDelete: "Supprimer",
		ContactTypesRead: "Lire",
		ContactTypesUpdate: "Modifier",
		ContractTypesAdd: "Ajouter",
		ContractTypesDelete: "Supprimer",
		ContractTypesRead: "Lire",
		ContractTypesUpdate: "Modifier",
		DegreesAdd: "Ajouter",
		DegreesDelete: "Supprimer",
		DegreesRead: "Lire",
		DegreesUpdate: "Modifier",
		DocumentTemplatesAdd: "Ajouter",
		DocumentTemplatesDelete: "Supprimer",
		DocumentTemplatesRead: "Lire",
		DocumentTemplatesUpdate: "Modifier",
		EmailTemplatesAdd: "Ajouter",
		EmailTemplatesDelete: "Supprimer",
		EmailTemplatesRead: "Lire",
		EmailTemplatesUpdate: "Modifier",
		ErrorLogRead: "Lire",
		ExpertisesAdd: "Ajouter",
		ExpertisesDelete: "Supprimer",
		ExpertisesRead: "Lire",
		ExpertisesUpdate: "Modifier",
		FacilitiesAdd: "Ajouter",
		FacilitiesDelete: "Supprimer",
		FacilitiesRead: "Lire",
		FacilitiesUpdate: "Modifier",
		GovernmentServicesAdd: "Ajouter",
		GovernmentServicesDelete: "Supprimer",
		GovernmentServicesRead: "Lire",
		GovernmentServicesUpdate: "Modifier",
		GroupsAdministrator: "Administrateur des groupes",
		GroupsAll: "Tous les groupes",
		GroupsRead: "Lire",
		GroupsUpdate: "Modifier",
		invoiceServicesAll: "Tous les services",
		invoiceServicesDelete: "Supprimer",
		invoiceServicesRead: "Lire",
		invoiceServicesUpdate: "Modifier",
		InvoicingAccess: "Accès",
		InvoicingGroupsAdd: "Ajouter",
		InvoicingGroupsDelete: "Supprimer",
		InvoicingGroupsRead: "Lire",
		InvoicingGroupsUpdate: "Modifier",
		InvoicingMomentsAdd: "Ajouter",
		InvoicingMomentsDelete: "Supprimer",
		InvoicingMomentsRead: "Lire",
		InvoicingMomentsUpdate: "Modifier",
		JobFunctionsAdd: "Ajouter",
		JobFunctionsDelete: "Supprimer",
		JobFunctionsRead: "Lire",
		JobFunctionsUpdate: "Modifier",
		JointCommitteesAdd: "Ajouter",
		JointCommitteesDelete: "Supprimer",
		JointCommitteesRead: "Lire",
		JointCommitteesUpdate: "Modifier",
		LanguagesAdd: "Ajouter",
		LanguagesDelete: "Supprimer",
		LanguagesRead: "Lire",
		LanguagesUpdate: "Modifier",
		LocationsAdd: "Ajouter",
		LocationsDelete: "Supprimer",
		LocationsRead: "Lire",
		LocationsUpdate: "Modifier",
		NacebelCodesAdd: "Ajouter",
		NacebelCodesDelete: "Supprimer",
		NacebelCodesRead: "Lire",
		NacebelCodesUpdate: "Modifier",
		NationalitiesAdd: "Ajouter",
		NationalitiesDelete: "Supprimer",
		NationalitiesRead: "Lire",
		NationalitiesUpdate: "Modifier",
		OpportunitiesAdd: "Ajouter",
		OpportunitiesAll: "Toutes les opportunités",
		OpportunitiesDelete: "Supprimer",
		OpportunitiesRead: "Lire",
		OpportunitiesUpdate: "Modifier",
		OpportunityOriginsAdd: "Ajouter",
		OpportunityOriginsDelete: "Supprimer",
		OpportunityOriginsRead: "Lire",
		OpportunityOriginsUpdate: "Modifier",
		ParticipantsAdd: "Ajouter",
		ParticipantsDelete: "Supprimer",
		ParticipantsRead: "Lire",
		ParticipantsUpdate: "Modifier",
		PartnersAdd: "Ajouter",
		PartnersDelete: "Supprimer",
		PartnersRead: "Lire",
		PartnersUpdate: "Modifier",
		PlanningDisplay: "Montrer",
		PrivateContactInformationRead: "Lire",
		PrivateContactInformationWrite: "Modifier",
		ProjectGroupsAdd: "Ajouter",
		ProjectGroupsDelete: "Supprimer",
		ProjectGroupsRead: "Lire",
		ProjectGroupsUpdate: "Modifier",
		ProjectParticipantsAdministrator: "Administrateur des participants",
		ProjectParticipantsAll: "Tous les participants",
		ProjectParticipantsRead: "Lire",
		ProjectParticipantsUpdate: "Modifier",
		ProjectsAdd: "Ajouter",
		ProjectsAll: "Tous les projets",
		ProjectsDelete: "Supprimer",
		ProjectsRead: "Lire",
		ProjectsUpdate: "Modifier",
		ProvincesAdd: "Ajouter",
		ProvincesDelete: "Supprimer",
		ProvincesRead: "Lire",
		ProvincesUpdate: "Modifier",
		RolesAdd: "Ajouter",
		RolesDelete: "Supprimer",
		RolesRead: "Lire",
		RolesUpdate: "Modifier",
		SectorsAdd: "Ajouter",
		SectorsDelete: "Supprimer",
		SectorsRead: "Lire",
		SectorsUpdate: "Modifier",
		ServiceTypesAdd: "Ajouter",
		ServiceTypesDelete: "Supprimer",
		ServiceTypesRead: "Lire",
		ServiceTypesUpdate: "Modifier",
		SessionRegistrationAppAccess: "Accès",
		SessionsAdministrator: "Administrateur des Sessions",
		SessionsAll: "Toutes les sessions",
		SessionsDelete: "Supprimer",
		SessionsRead: "Lire",
		SessionsUpdate: "Modifier",
		SessionTemplatesAdd: "Ajouter",
		SessionTemplatesDelete: "Supprimer",
		SessionTemplatesRead: "Lire",
		SessionTemplatesUpdate: "Modifier",
		SettingsRead: "Lire",
		SettingsUpdate: "Modifier",
		StatutesAdd: "Ajouter",
		StatutesDelete: "Supprimer",
		StatutesRead: "Lire",
		StatutesUpdate: "Modifier",
		TasksAll: "Tous les tâches ",
		TaskSubjectsAdd: "Ajouter",
		TaskSubjectsDelete: "Supprimer",
		TaskSubjectsRead: "Lire",
		TaskSubjectsUpdate: "Modifier",
		TopicsAdd: "Ajouter",
		TopicsDelete: "Supprimer",
		TopicsRead: "Lire",
		TopicsUpdate: "Modifier",
		UsersAdd: "Ajouter",
		UsersDelete: "Supprimer",
		UsersRead: "Lire",
		UsersUpdate: "Modifier",
		SessionsMinimumBillingHours: "Minimum des heures",
		SessionsExtraBillingHours: "Heures supplémentaires",
		SessionTemplatesMinimumBillingHours: "Minimum des heures",
		SessionTemplatesExtraBillingHours: "Heures supplémentaires"
	},
	personInfo: "Infos personnelles",
	phoneNumber: "Numéro de téléphone",
	physicalInteractionSatisfactionQuestion: "Qu'avez-vous pensé de l'interaction dans l'orientation physique en outplacement",
	plan: "Le plan",
	Planned: "Planifié",
	PlannedGreen: "Planifié",
	PlannedOrange: "Planifié",
	PlannedRed: "Planifié",
	plannedStart: "Début planifié",
	plannedStop: "Arrêt planifié",
	planning: {
		currentWeek: "Cette semaine",
		friday: "Vendredi",
		monday: "Lundi",
		nextWeek: "La semaine prochaine",
		no_information: "Aucune information",
		occupation_message: "Occupé à {{percentage}} %",
		openSession: "Ouvrir une session",
		planning: "La planification",
		prevWeek: "La semaine passée",
		saturday: "Samedi",
		thursday: "Jeudi",
		tuesday: "Mardi",
		wednesday: "Mercredi"
	},
	planSessions: "Planifier les sessions",
	Plus45: "45+",
	postalCode: "Code postal",
	preferedFutureFunction: "Fonction future de préférance",
	present: "Présent",
	price: "Prix €",
	priority: "priority",
	priorityDown: "priorityDown",
	priorityUp: "priorityUp",
	privacy: "Vie privée",
	privateContactInformation: "Coordonnées privées",
	privateEmailAddress: "E-mail privé",
	privateMobilePhoneNumber: "Numéro de GSM privé",
	processed: "Traité",
	processRequestInitiated: "La demande de traitement de la liste excel a été acceptée",
	project: "Projet",
	projectFollowUpTaskStatus: "Statut de la tâche de projet",
	projectGroup: "Groupe de projet",
	projectGroups: "Groupes de projet",
	projectName: "Le nom de projet",
	projectOrParticipant: "Soort dienstlijn???",
	projectParticipant: "Participant de projet",
	projectParticipantFollowUpTaskStatus: "Statut de la tâche du participant au projet",
	projectParticipantResponsible: "Responsable du participant",
	projectParticipantService: "Service au participant au projet",
	projectParticipantStatus: "Statut du participant au projet",
	projectResponsible: "Responsable du projet",
	projects: "Projets",
	projectService: "Service de projet",
	projectServices: "Services de projet",
	projectServiceStatus: "Statut du service de projet",
	projectShow: "Afficher le projet",
	projectStatus: "Statut du projet",
	promotionalGift: "Cadeau d'entreprise",
	province: "Province",
	provinces: "Provinces",
	publicTender: "Appel d'offres public",
	putNameOnInvoice: "Mentionner le nom sur la facture",
	ratingQuestion: "Comment évaluez-vous votre dernière session d'outplacement",
	ratingReasonQuestion: "Pourquoi",
	recipientEmailNotAvailable: "E-mail du destinataire n'est pas disponible.",
	reclamation: "Recouvrement",
	Rejected: "Rejeté",
	rejectionReason: "Raison du rejet",
	relatedTo: "Relatif à",
	remarks: "Remarques",
	reminder: "Rappel",
	remove: "Supprimer",
	repetition: "Le nombre de répétitions",
	replacement: "Remplacement",
	reportableGovernmentService: "Service gouvernemental à rapporter",
	requestParticipantsInfo: "Obtenir l'information de participants",
	requests: "Demandes",
	reset: "Restaurer",
	resources: "Ressources",
	responsible: "Responsable",
	responsibleFirstName: "Contact - Prénom",
	responsibleLastName: "Contact - Nom",
	role: "Rôle",
	roles: "Rôles",
	room: "Salle",
	salesPrice: "Prix de vente",
	satisfactionScore: "Score de satisfaction",
	Satisfied: "Satisfaits",
	save: "Sauvegarder",
	saveAndClose: "Sauvegarder et fermer",
	scheduler: {
		allDay: "Toute la journée",
		showFullDay: "Afficher toute la journée"
	},
	searchAllProjects: "Rechercher dans tous les projets",
	sector: "Secteur",
	sectors: "Secteurs",
	security: "Sécurité",
	select: "Sélectionner",
	select_at_least_one: "Veuillez sélectionner au moins 1 {{entity}}.",
	selectAll: "Tout sélectionner",
	selectColumns: "sélectionner des colonnes",
	selectExistingParticipantProjectChangesWillBeSaved: "Select a participant of this session. Upon saving, all current changes on this session will be saved as well.",
	selectionOfParticipants: "Sélectionner les participants",
	selectRecipients: "Veuillez sélectionner les destinataires",
	send: "Envoyer",
	sendEmail: "Envoyer l'e-mail",
	sendInvitation: "Envoyer l'invitation",
	sendSurveyOnClose: "Envoyer l'enquête à la fermeture",
	sendTo: "Envoyer à",
	sendUpdateInvitation: "Envoyer l'invitation à la mise à jour",
	seniorityPreviousEmployment: "Ancienneté chez ancien employeur",
	Servant: "Employé",
	service: "Service",
	serviceCode: "Code de service",
	services: "Services ",
	serviceShow: "serviceShow",
	serviceStatus: "Statut de service",
	serviceType: "Type de service",
	serviceTypes: "Types de service",
	session: "Session",
	Session: "Session",
	sessionFeedback: "Commentaires de la session",
	sessionFollowUpTaskStatus: "Statut de la tâche de session",
	sessionName: "Nom de session",
	sessionNotInApp: "La session n'est pas présente dans Outlook ",
	sessionNotInOutlook: "La session n'est pas présente dans l'application Web",
	sessionOccupationOrange: "Occupation de session % - Orange",
	sessionOccupationRed: "Occupation de session % - Rouge",
	sessionParticipant: "Participant à la session",
	sessionRegistrationApp: "App d'enregistrement de session",
	sessionResponsible: "Responsable de session",
	sessions: "Sessions",
	sessionStatus: "Statut de la session",
	sessionTemplate: "Modèle de session",
	sessionTemplateFollowUpTask: "Tâche de modèle de session",
	sessionTemplates: "Modèles de sessions",
	settings: "Paramètres",
	shortDescription: "Brève description",
	show: "Afficher",
	showAllGroups: "Toon les groupes",
	showAllOpportunities: "Afficher tout les opportunités ",
	showAllParticipants: "Afficher tout Participants ",
	showAllProjects: "Afficher tout projets",
	showAllSessions: "Afficher tout sessions",
	showAllTasks: "afficher toutes les tâches",
	showColumnChooser: "Afficher le sélecteur de colonnes",
	showFeedback: "Afficher les commentaires",
	showLBC1: 'Afficher "LBC 1"',
	showLBC2: 'Afficher "LBC 2"',
	showLBCPay: 'Afficher "Loopbaancheque - Betalend"',
	signature: "Signature",
	spread: "Dispersion",
	start: "Départ",
	startDate: "Date de début",
	status: "Statut",
	statute: "Statut",
	statutes: "Statuts",
	stopDate: "Date d'arrêt",
	Stopped: "Arrêté",
	stopReason: "Motif d'arrêt",
	street: "Rue",
	subject: "Sujet",
	subjectDe: "Sujet DE",
	subjectEn: "Sujet EN",
	subjectFr: "Sujet FR",
	subjectNl: "Sujet NL",
	subtotal: "Sous-total",
	subType: "Sous-type",
	success: "Réussite",
	support: "Soutien",
	surveyLink: "Lien d'évaluation",
	task: "Tâche",
	taskAssignee: "Responsable de la tâche",
	taskAttachments: "Annexes à la tâche",
	tasks: "Tâches",
	taskSubject: "Sujet de la tâche",
	taskSubjects: "Sujets de la tâche",
	template: "Modèle",
	theseAreTheServerErrorMessages: "Voici les erreurs de validation du serveur:",
	thresholds: "Obstacles",
	timepicker: {
		cancel: "Annuler",
		now: "MAINTENANT",
		selectNow: "Sélectionner maintenant",
		set: "",
		toggleClock: "Changer l'horloge",
		toggleTimeSelector: "Ouvrir/fermer"
	},
	title: "Titre",
	to: "A",
	toBeDetirmined: "A déterminer",
	ToBeInvoiced: "A facturer",
	topic: "Thème",
	topics: "Thèmes",
	totalAmount: "Montant total €",
	totalHours: "totalHours",
	type: "Type",
	Undefined: "X",
	uniqueKey: {
		IX_AbsenceReasons_Name: "Ce nom",
		IX_CatalogueServices_Code: "Ce code",
		IX_Companies_VatNumber: "Ce numéro de TVA",
		IX_CompanyGroups_Name: "Ce nom",
		IX_Contact_Email: "Cette adresse e-mail",
		IX_ContactTypes_Name: "Ce nom",
		IX_ContractTypes_Name: "Ce nom",
		IX_Degrees_Name: "Ce nom",
		IX_DocumentTemplates_Name: "Ce nom",
		IX_EmailTemplates_Name: "Ce nom",
		IX_Expertises_Name: "Ce nom",
		IX_Facilities_Name: "Ce nom",
		IX_InvoicingMoments_Name: "Ce nom",
		IX_JobFunctions_Name: "Ce nom",
		IX_JointCommittees_Name: "Ce nom",
		IX_Languages_Name: "Ce nom",
		IX_NacebelCodes_Name: "Ce nom",
		IX_Nationalities_Name: "Ce nom",
		IX_Partners_VatNumber: "Ce numéro de TVA",
		IX_Roles_Name: "Ce nom",
		IX_Sectors_Name: "Ce nom",
		IX_ServiceTypes_Name: "Ce nom",
		IX_sessions: "Ces participants sont encore dans ces sessions:",
		IX_SessionTemplateFollowUpTasks_Subject_SessionTemplateId: "ce sujec dans cette modèle",
		IX_Statutes_Name: "Ce nom",
		IX_TaskSubjects_Name: "Ce nom",
		IX_Topics_Name: "Ce nom",
		IX_Users_Email: "Cette adresse e-mail"
	},
	Unsatisfied: "Insatisfaits",
	upload: {
		cancel: "Anuler",
		clearSelectedFiles: "Vider",
		dropFilesHere: "Déposer ici vos fichiers pour télécharger",
		files: "Fichiers",
		headerStatusUploaded: "Prêt",
		headerStatusUploading: "Télécharger",
		invalidFileExtension: "Type de fichier autorisé",
		invalidFiles: "Fichier(s) non valide(s). Veuillez vérifier les conditions de téléchargement des fichiers.",
		invalidMaxFileSize: "Fichier trop lourd",
		invalidMinFileSize: "Fichier trop petit",
		remove: "Effacer",
		retry: "Réessayer",
		select: "Sélectionner les fichiers",
		total: "Total",
		uploadSelectedFiles: "Télécharger"
	},
	uploadExcel: "Télécharger Excel",
	uploadParticipantsList: "Télécharger participants",
	usedHours: "usedHours",
	user: "Utilisateur",
	users: "Utilisateurs",
	vatNumber: "Numéro de TVA",
	vatNumberFormat: "Mauvais format",
	VerySatisfied: "Très satisfait",
	VeryUnsatisfied: "Très insatisfait",
	warning_empty_emails: "Remarque: tous les participants n'ont pas d'adresse e-mail.",
	Weekly: "Chaque semaine",
	welcome_message: "Bienvenue",
	work: "Travail",
	Worker: "Ouvrier",
	workingExperiences: "Expériences de travail",
	year: "Année",
	yes: "Oui",
	yourprofile: "Votre profil",
	zoomSessionId: "ID de session Zoom",
	errorDownloadingAttachments: "Une erreur s'est produite lors du téléchargement des pièces jointes.",
	noAttachmentsToDownload: "Aucune pièce jointe à télécharger.",
	noAttachmentsSelected: "Aucune pièce jointe sélectionnée.",
	downloadAttachments: "Télécharger les pièces jointes",
	accessToTraP: "Accès a TraP",
	startDateIV1: "Date de début IV1",
	startDateIV2: "Date de début IV2",
	countAsPresent: "compte comme présent",
	minimumBillingHours: "Minimum des heures",
	extraBillingHours: "Heures supplémentaires",
	showOnlyToBeInvoiced: "Afficher uniquement  facturer",
	calculatedHours: "Heures calculées totales"
};
export default translationFR;
