enum Permission {
	RolesRead = "rolesRead",
	RolesAdd = "rolesAdd",
	RolesUpdate = "rolesUpdate",
	RolesDelete = "rolesDelete",

	UsersRead = "usersRead",
	UsersAdd = "usersAdd",
	UsersUpdate = "usersUpdate",
	UsersDelete = "usersDelete",

	SettingsRead = "settingsRead",
	SettingsUpdate = "settingsUpdate",

	StatutesRead = "statutesRead",
	StatutesAdd = "statutesAdd",
	StatutesUpdate = "statutesUpdate",
	StatutesDelete = "statutesDelete",

	InvoicingAccess = "invoicingAccess",

	ServiceTypesRead = "serviceTypesRead",
	ServiceTypesAdd = "serviceTypesAdd",
	ServiceTypesUpdate = "serviceTypesUpdate",
	ServiceTypesDelete = "serviceTypesDelete",

	DegreesRead = "degreesRead",
	DegreesAdd = "degreesAdd",
	DegreesUpdate = "degreesUpdate",
	DegreesDelete = "degreesDelete",

	ContactTypesRead = "contactTypesRead",
	ContactTypesAdd = "contactTypesAdd",
	ContactTypesUpdate = "contactTypesUpdate",
	ContactTypesDelete = "contactTypesDelete",

	NationalitiesRead = "nationalitiesRead",
	NationalitiesAdd = "nationalitiesAdd",
	NationalitiesUpdate = "nationalitiesUpdate",
	NationalitiesDelete = "nationalitiesDelete",

	SectorsRead = "sectorsRead",
	SectorsAdd = "sectorsAdd",
	SectorsUpdate = "sectorsUpdate",
	SectorsDelete = "sectorsDelete",

	ContractTypesRead = "contractTypesRead",
	ContractTypesAdd = "contractTypesAdd",
	ContractTypesUpdate = "contractTypesUpdate",
	ContractTypesDelete = "contractTypesDelete",

	JobFunctionsRead = "jobFunctionsRead",
	JobFunctionsAdd = "jobFunctionsAdd",
	JobFunctionsUpdate = "jobFunctionsUpdate",
	JobFunctionsDelete = "jobFunctionsDelete",

	AbsenceReasonsRead = "absenceReasonsRead",
	AbsenceReasonsAdd = "absenceReasonsAdd",
	AbsenceReasonsUpdate = "absenceReasonsUpdate",
	AbsenceReasonsDelete = "absenceReasonsDelete",

	LanguagesRead = "languagesRead",
	LanguagesAdd = "languagesAdd",
	LanguagesUpdate = "languagesUpdate",
	LanguagesDelete = "languagesDelete",

	ExpertisesRead = "expertisesRead",
	ExpertisesAdd = "expertisesAdd",
	ExpertisesUpdate = "expertisesUpdate",
	ExpertisesDelete = "expertisesDelete",

	JointCommitteesRead = "jointCommitteesRead",
	JointCommitteesAdd = "jointCommitteesAdd",
	JointCommitteesUpdate = "jointCommitteesUpdate",
	JointCommitteesDelete = "jointCommitteesDelete",

	TopicsRead = "topicsRead",
	TopicsAdd = "topicsAdd",
	TopicsUpdate = "topicsUpdate",
	TopicsDelete = "topicsDelete",

	FacilitiesRead = "facilitiesRead",
	FacilitiesAdd = "facilitiesAdd",
	FacilitiesUpdate = "facilitiesUpdate",
	FacilitiesDelete = "facilitiesDelete",

	InvoicingMomentsRead = "invoicingMomentsRead",
	InvoicingMomentsAdd = "invoicingMomentsAdd",
	InvoicingMomentsUpdate = "invoicingMomentsUpdate",
	InvoicingMomentsDelete = "invoicingMomentsDelete",

	NacebelCodesRead = "nacebelCodesRead",
	NacebelCodesAdd = "nacebelCodesAdd",
	NacebelCodesUpdate = "nacebelCodesUpdate",
	NacebelCodesDelete = "nacebelCodesDelete",

	ContactsRead = "contactsRead",
	ContactsAdd = "contactsAdd",
	ContactsUpdate = "contactsUpdate",
	ContactsDelete = "contactsDelete",

	CompaniesRead = "companiesRead",
	CompaniesAdd = "companiesAdd",
	CompaniesUpdate = "companiesUpdate",
	CompaniesDelete = "companiesDelete",

	CompanyGroupsRead = "companyGroupsRead",
	CompanyGroupsAdd = "companyGroupsAdd",
	CompanyGroupsUpdate = "companyGroupsUpdate",
	CompanyGroupsDelete = "companyGroupsDelete",

	PartnersRead = "partnersRead",
	PartnersAdd = "partnersAdd",
	PartnersUpdate = "partnersUpdate",
	PartnersDelete = "partnersDelete",

	ParticipantsRead = "participantsRead",
	ParticipantsAdd = "participantsAdd",
	ParticipantsUpdate = "participantsUpdate",
	ParticipantsDelete = "participantsDelete",

	OpportunitiesRead = "opportunitiesRead",
	OpportunitiesAll = "opportunitiesAll",
	OpportunitiesAdd = "opportunitiesAdd",
	OpportunitiesUpdate = "opportunitiesUpdate",
	OpportunitiesDelete = "opportunitiesDelete",

	LocationsAdd = "locationsAdd",
	LocationsRead = "locationsRead",
	LocationsUpdate = "locationsUpdate",
	LocationsDelete = "locationsDelete",

	CoachesAdd = "coachesAdd",
	CoachesRead = "coachesRead",
	CoachesUpdate = "coachesUpdate",
	CoachesDelete = "coachesDelete",
	PrivateContactInformationRead = "privateContactInformationRead",
	PrivateContactInformationWrite = "privateContactInformationWrite",

	ProvincesAdd = "provincesAdd",
	ProvincesRead = "provincesRead",
	ProvincesUpdate = "provincesUpdate",
	ProvincesDelete = "provincesDelete",

	CatalogueServicesAdd = "catalogueServicesAdd",
	CatalogueServicesRead = "catalogueServicesRead",
	CatalogueServicesUpdate = "catalogueServicesUpdate",
	CatalogueServicesDelete = "catalogueServicesDelete",

	ProjectsRead = "projectsRead",
	ProjectsAll = "projectsAll",
	ProjectsAdd = "projectsAdd",
	ProjectsUpdate = "projectsUpdate",
	ProjectsDelete = "projectsDelete",

	ProjectParticipantsRead = "projectParticipantsRead",
	ProjectParticipantsAll = "projectParticipantsAll",
	ProjectParticipantsAdministrator = "projectParticipantsAdministrator",
	ProjectParticipantsUpdate = "projectParticipantsUpdate",

	SessionsRead = "sessionsRead",
	SessionsAll = "sessionsAll",
	SessionsDelete = "sessionsDelete",
	SessionsAdministrator = "sessionsAdministrator",
	SessionsUpdate = "sessionsUpdate",
	AllowUpdatingSendSurveyOnClose = "allowUpdatingSendSurveyOnClose",
	AllowAddingSessionWithoutTemplate = "allowAddingSessionWithoutTemplate",

	SessionsMinimumBillingHours = "sessionsMinimumBillingHours",
	SessionsExtraBillingHours = "sessionsExtraBillingHours",
	SessionTemplatesMinimumBillingHours = "sessionTemplatesMinimumBillingHours",
	SessionTemplatesExtraBillingHours = "sessionTemplatesExtraBillingHours",

	GroupsRead = "groupsRead",
	GroupsAll = "groupsAll",
	GroupsAdministrator = "groupsAdministrator",
	GroupsUpdate = "groupsUpdate",

	DocumentTemplatesAdd = "documentTemplatesAdd",
	DocumentTemplatesRead = "documentTemplatesRead",
	DocumentTemplatesUpdate = "documentTemplatesUpdate",
	DocumentTemplatesDelete = "documentTemplatesDelete",

	TasksAll = "tasksAll",

	TaskSubjectsAdd = "taskSubjectsAdd",
	TaskSubjectsRead = "taskSubjectsRead",
	TaskSubjectsUpdate = "taskSubjectsUpdate",
	TaskSubjectsDelete = "taskSubjectsDelete",

	SessionTemplatesAdd = "sessionTemplatesAdd",
	SessionTemplatesRead = "sessionTemplatesRead",
	SessionTemplatesUpdate = "sessionTemplatesUpdate",
	SessionTemplatesDelete = "sessionTemplatesDelete",

	InvoicingGroupsRead = "invoicingGroupsRead",
	InvoicingGroupsAdd = "invoicingGroupsAdd",
	InvoicingGroupsUpdate = "invoicingGroupsUpdate",
	InvoicingGroupsDelete = "invoicingGroupsDelete",

	EmailTemplatesAdd = "emailTemplatesAdd",
	EmailTemplatesRead = "emailTemplatesRead",
	EmailTemplatesUpdate = "emailTemplatesUpdate",
	EmailTemplatesDelete = "emailTemplatesDelete",

	BackOfficeTasksRead = "backOfficeTasksRead",

	ErrorLogRead = "errorLogRead",

	GovernmentServicesAdd = "governmentServicesAdd",
	GovernmentServicesRead = "governmentServicesRead",
	GovernmentServicesUpdate = "governmentServicesUpdate",
	GovernmentServicesDelete = "governmentServicesDelete",

	ProjectGroupsAdd = "projectGroupsAdd",
	ProjectGroupsRead = "projectGroupsRead",
	ProjectGroupsUpdate = "projectGroupsUpdate",
	ProjectGroupsDelete = "projectGroupsDelete",

	OpportunityOriginsAdd = "opportunityOriginsAdd",
	OpportunityOriginsRead = "opportunityOriginsRead",
	OpportunityOriginsUpdate = "opportunityOriginsUpdate",
	OpportunityOriginsDelete = "opportunityOriginsDelete",

	PlanningDisplay = "planningDisplay",

	AllowSettingInvoicedStatus = "allowSettingInvoicedStatus"
}

export default Permission;
