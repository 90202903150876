import React, { useEffect, useReducer, useRef, useState } from "react";

import { Checkbox, CheckboxChangeEvent, Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, hideLoader, render, showLoader } from "@selas/state-management";
import { Confirm, EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import { cloneDeep, some } from "lodash";
import camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialPermissionState } from "../../../../state";
import { permissionReducer, roleReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { Permission } from "../../../../utils/enums";
import { IRole, IUser, IUserRole } from "../../../../utils/types/models";

import editorStyles from "../../../../assets/editor.module.scss";
import roleEditorStyles from "./editor.module.scss";

const RoleEditor: React.FC<IAddEntityScreenProps<IRole> | IEditEntityScreenProps<IRole>> = (props: IAddEntityScreenProps<IRole> | IEditEntityScreenProps<IRole>) => {
	const { t } = useTranslation();
	const [roleState, roleDispatch] = useReducer(roleReducer, getInitialState<IRole>());
	const [permissionState, permissionDispatch] = useReducer(permissionReducer, initialPermissionState);
	const [role, setRole] = useState<IRole>({
		id: (props as IEditEntityScreenProps<IRole>).recordId || 0,
		name: "",
		active: true,
		permissions: {}
	});
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ThunkDispatch<IApplicationState, {}, AnyAction> = useDispatch<ThunkDispatch<IApplicationState, {}, AnyAction>>();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (!hasPermission(Permission.RolesRead)) {
			props.actionButtonClicked(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		apiService.callApi(permissionDispatch, Endpoint.Permissions, "GET");
	}, [apiService]);

	useEffect(() => {
		if (permissionState.loading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [permissionState.loading, reduxDispatch]);

	useEffect(() => {
		if (roleState.entity) {
			setRole(roleState.entity);
		}
	}, [roleState.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent): void {
		setRole(handleChange(role, event));
		setDataChanged(true);
	}

	function onPermissionChange(event: CheckboxChangeEvent): void {
		const roleRecord: IRole = cloneDeep(role);
		roleRecord.permissions[camelCase(event.target.element.id)] = event.value;
		if (some(currentUser.userRoles, { roleId: roleRecord.id })) {
			const confirmKey: string = newKey("confirm_change");
			reduxDispatch(
				render(confirmKey, Confirm, {
					title: t("edit"),
					children: t("confirm_role_update_current_user"),
					onConfirm: () => {
						setRole(roleRecord);
						setDataChanged(true);
						reduxDispatch(derender(confirmKey));
					},
					onDecline: () => reduxDispatch(derender(confirmKey))
				})
			);
		} else {
			setRole(roleRecord);
			setDataChanged(true);
		}
	}

	function onActionButtonClicked(close: boolean, record: IRole): void {
		if (record && !close) {
			setRole(record);
		}
		if (record && some(currentUser.userRoles, { roleId: record.id })) {
			reduxDispatch(apiService.callApiRedux(Endpoint.CurrentUser, "GET"));
			if (
				some(currentUser.userRoles, (userRole: IUserRole) => {
					return record.id !== userRole.roleId && userRole.role.permissions[Permission.RolesRead];
				}) ||
				record.permissions[Permission.RolesRead]
			) {
				props.actionButtonClicked(close, record);
			} else {
				props.actionButtonClicked(close);
			}
		} else {
			props.actionButtonClicked(close, record);
		}
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(role.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!role.permissions || Object.keys(role.permissions).length <= 0) {
			messages.push(t("select_at_least_one", { entity: t("permission").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean =
		(props as IEditEntityScreenProps<IRole>).readonly ||
		(hasPermission(Permission.RolesRead) && ((!role.id && !hasPermission(Permission.RolesAdd)) || (role.id && !hasPermission(Permission.RolesUpdate))));

	return (
		<EntityEditor
			width="70%"
			record={role}
			endpoint={Endpoint.Roles}
			entityState={roleState}
			entityType={t("role")}
			dispatch={roleDispatch}
			dataChanged={dataChange}
			readonly={readonly}
			recordName={role.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className={"row " + roleEditorStyles.rolesRow}>
					<div className="col-md-12">
						<label className="k-form-field">
							<span>{t("name")}</span>
							<Input name="name" ref={firstField} value={role.name} onChange={onChange} disabled={readonly} required />
						</label>
						<div className="k-form-field">
							<div>{t("active")}</div>
							<YesNoSwitch name="active" checked={role.active} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</div>
				<div className={"row " + roleEditorStyles.rolesRow}>
					<div className="col-md-12">
						{Object.keys(permissionState.permissions).map((group: string) => {
							return (
								<div key={group}>
									<div className={roleEditorStyles.rolesGrouptitle}>{t(group)}</div>
									<div className={roleEditorStyles.rolesItems}>
										{Object.keys(permissionState.permissions[group]).map((subGroup: string) => {
											return (
												<div className={"row " + editorStyles.switchRow + " " + roleEditorStyles.rolesRow} key={subGroup}>
													<div className="col-md-2">
														<strong>{t(subGroup)}</strong>
													</div>
													{permissionState.permissions[group][subGroup].map((permission: string, index: number) => {
														const smallPermission: string = camelCase(permission);
														const checked: boolean = role && role.permissions && role.permissions[smallPermission] ? role.permissions[smallPermission] : false;
														return (
															<>
																{index !== 0 && index % 5 === 0 && <div className="col-md-2" />}
																<div className="col-md-2" key={permission}>
																	<label>
																		<Checkbox
																			type="checkbox"
																			id={permission}
																			checked={checked}
																			name={permission}
																			onChange={onPermissionChange}
																			disabled={readonly}
																		/>
																		<label className="k-checkbox-label" htmlFor={permission} style={{ marginLeft: "5px" }}>
																			{t("permissions." + permission)}
																		</label>
																	</label>
																</div>
															</>
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</EntityEditor>
	);
};

export default RoleEditor;
