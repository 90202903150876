import React, { SetStateAction, useCallback, useEffect, useReducer, useState,  Dispatch as ReactDispatch } from "react";

import { State } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, render } from "@selas/state-management";
import { commandCell, customCell, dateCell, Loader } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialSessionState } from "../../../state";
import { sessionReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Color, Permission } from "../../../utils/enums";
import { ISession, IUser } from "../../../utils/types/models";
import SessionEditor from "../../editors/project/session";

import { colorCell } from "../../global/colorCell";

interface IProps {
	groupId: number;
	participantId: number;
	setParticipantId: ReactDispatch<SetStateAction<number>>;
}

const GroupParticipantsDetailSessions: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();
	const [gridState, setGridState] = useState<State>({
		skip: 0,
		take: 25
	});
	const [sessionsState, sessionsDispatch] = useReducer(sessionReducer, initialSessionState);
	const apiService: ApiCommunicator = useApiService();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	const refreshGrid: () => void = useCallback(() => {
		if (props.groupId !== 0) {
			apiService.callApi(sessionsDispatch, Endpoint.SessionsByParticipant, "POST", { groupId: props.groupId, participantId: props.participantId }, gridState);
		}
	}, [apiService, gridState, props.groupId, props.participantId]);

	useEffect(() => {
		refreshGrid();
	}, [refreshGrid]);

	useEffect(() => {
		if (!sessionsState.isUpdating && sessionsState.updatedEntity) {
			refreshGrid();
		}
	}, [sessionsState.isUpdating, sessionsState.updatedEntity, refreshGrid]);

	function editSession(session: ISession): void {
		const editSessionKey: string = newKey("session");
		reduxDispatch(
			render(editSessionKey, SessionEditor, {
				recordId: session.id,
				projectId: session.projectId,
				readonly: !(hasPermission(Permission.SessionsUpdate) || session.responsibleUserId === currentUser.id),
				groupId: props.groupId,
				actionButtonClicked: (close: boolean, record: ISession) => {
					if (close) {
						reduxDispatch(derender(editSessionKey));
					}
					if (record) {
						refreshGrid();
					}
				}
			})
		);
	}

	function getTdColor(props: GridCellProps): Color {
		if(props.dataItem.countAsPresent) {
			return Color.Red
		}
		return Color.Green;
	}

	return (
		<>
			{(sessionsState.isListLoading || sessionsState.isUpdating) && <Loader />}
			<Grid
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
				data={sessionsState.list}
				{...gridState}
				total={sessionsState.totalCount}
				filterable
				sortable
				style={{ height: "450px" }}
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				resizable={false}
			>
				<GridToolbar>
					<div className="toolbarButtonContainer d-flex w-100 align-items-center justify-content-between">
					<i className="refreshButton las la-sync" onClick={refreshGrid} />
					<i className="refreshButton las la-times" onClick={() => props.setParticipantId(0)} />
					</div>
				</GridToolbar>
				{/* <GridColumn
					field="status"
					title={t("projectParticipantStatus")}
					cell={customCell(colorCell(true, translatedCell(), getProjectParticipantStatusColor), colorCellClassName)}
					filterCell={enumFilterCell(ProjectParticipantStatus)}
				/>
				<GridColumn field="fullName" title={t("fullName")} /> */}
				<GridColumn cell={customCell(colorCell(true, undefined, getTdColor ))} field="sessionName" title={t("name")} />
				{/* <GridColumn field="groupName" title={t("group")} /> */}
				{/* <GridColumn field="individualHours" title={t("individualHours")} cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} />
				<GridColumn field="collectiveHours" title={t("collectiveHours")} cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} /> */}
				<GridColumn field="responsibleUserName" title={t("coach")} />
				<GridColumn field="actualStart" title={t("actualStart")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
				<GridColumn field="actualStop" title={t("actualStop")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
				<GridColumn
					title={t("actions")}
					width="60px"
					filterable={false}
					editable={false}
					sortable={false}
					groupable={false}
					headerClassName="notSortable"
					cell={customCell(
						commandCell([
							{
								tooltip: t("editSession"),
								iconClass: "las la-pencil-alt",
								recordAction: editSession,
								showCommand: (dataItem: ISession) =>
									hasPermission(Permission.SessionsUpdate) && (dataItem.responsibleUserId === currentUser.id || hasPermission(Permission.SessionsAdministrator))
							}
						])
					)}
				/>
			</Grid>
		</>
	);
};

export default GroupParticipantsDetailSessions;
