import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import {
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	ManageableMultiSelect,
	SearchBox,
	setEntity,
	TextArea
} from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { catalogueServiceReducer, invoicingMomentReducer, languageReducer, projectServiceReducer, serviceTypeReducer } from "../../../state/reducers";
import { Permission, ProjectServiceStatus } from "../../../utils/enums";
import { ICatalogueService, IInvoicingMoment, ILanguage, IProjectService, IProjectServiceBase, IServiceLanguage, IServiceType } from "../../../utils/types/models";
import CatalogueServiceEditor from "../catalogue/catalogueService";
import ServiceTypeEditor from "../catalogue/serviceType";
import InvoicingMomentEditor from "../masterdata/invoicingMoment";
import LanguageEditor from "../personInfo/language";
import { renderProjectServiceStatus } from "./grids/projectServices";
import filter from "lodash/filter";

interface IProps {
	projectId: number;
}

const ProjectServiceEditor: React.FC<(IAddEntityScreenProps<IProjectService> | IEditEntityScreenProps<IProjectService>) & IProps> = (
	props: (IAddEntityScreenProps<IProjectService> | IEditEntityScreenProps<IProjectService>) & IProps
) => {
	const { t } = useTranslation();

	const intialProjectService: IProjectService = {
		id: (props as IEditEntityScreenProps<IProjectService>).recordId || 0,
		amount: 1,
		code: "",
		invoicingMomentId: null,
		languages: null,
		collectiveHours: 0,
		individualHours: 0,
		numberOfMonths: 0,
		salesPrice: 0,
		projectId: props.projectId,
		status: ProjectServiceStatus.Forecast,
		typeId: null
	};
	const [projectService, setProjectService] = useState<IProjectService>(intialProjectService);
	const [catalogueService, setCatalogueService] = useState<ICatalogueService>();
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [projectServiceState, projectServiceDispatch] = useReducer(projectServiceReducer, getInitialState<IProjectService>());
	const [serviceTypeState, serviceTypeDispatch] = useReducer(serviceTypeReducer, getInitialState<IServiceType>());
	const [languageState, languageDispatch] = useReducer(languageReducer, getInitialState<ILanguage>());
	const [invoicingMomentState, invoicingMomentDispatch] = useReducer(invoicingMomentReducer, getInitialState<IInvoicingMoment>());
	const [catalogueServiceState, catalogueServiceDispatch] = useReducer(catalogueServiceReducer, getInitialState<ICatalogueService>());

	const firstCombobox: React.MutableRefObject<ComboBox> = useRef();
	const firstInput: React.MutableRefObject<Input> = useRef();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (projectServiceState.entity) {
			setProjectService(projectServiceState.entity);
		}
	}, [projectServiceState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.ProjectsAdd, Permission.ProjectsUpdate)) {
			apiService.callApi(languageDispatch, Endpoint.Languages, "GET");
		}
	}, [apiService]);

	function onChange(event: InputChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent): void {
		setProjectService(handleChange(projectService, event));
		setDataChanged(true);
	}

	function onCatalogueServiceChange(newCatalogueService: ICatalogueService): void {
		setCatalogueService(newCatalogueService);
		if (newCatalogueService) {
			const newProjectParticipantService: IProjectService = {
				...newCatalogueService,
				id: projectService.id,
				projectId: projectService.projectId,
				status: projectService.status,
				amount: projectService.amount,
				languages: map(newCatalogueService.languages, (language: IServiceLanguage<ICatalogueService>) => ({
					serviceId: projectService.id,
					languageId: language.languageId,
					language: language.language
				}))
			};
			updateProjectService(newProjectParticipantService);
		}
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IProjectService, idField: keyof IProjectService): void {
		const newProjectService: IProjectService = setEntity(projectService, entity, field, idField);
		updateProjectService(newProjectService);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const newProjectService: IProjectService = cloneDeep(projectService);

		if (event.value) {
			if (event.target.name === "languages") {
				newProjectService.languages = [];
				for (const lang of event.value) {
					newProjectService.languages.push({ serviceId: newProjectService.id, languageId: lang.id, language: lang });
				}
			}
			updateProjectService(newProjectService);
		}
	}

	function addOrUpdateLanguage(language: ILanguage): void {
		const newProjectService: IProjectService = cloneDeep(projectService);
		const existingServiceLanguage: IServiceLanguage<IProjectServiceBase> = find(newProjectService.languages, { languageId: language.id });
		if (existingServiceLanguage) {
			existingServiceLanguage.language = language;
		} else {
			newProjectService.languages.push({
				serviceId: newProjectService.id,
				languageId: language.id,
				language
			});
		}
		apiService.callApi(languageDispatch, Endpoint.Languages, "GET");
		updateProjectService(newProjectService);
	}

	function updateProjectService(newProjectService: IProjectService): void {
		setProjectService(newProjectService);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "typeId":
				dispatch = serviceTypeDispatch;
				filterEndpoint = Endpoint.ServiceTypes;
				break;
			case "invoicingMomentId":
				dispatch = invoicingMomentDispatch;
				filterEndpoint = Endpoint.InvoicingMoments;
				break;
			case "catalogueService":
				dispatch = catalogueServiceDispatch;
				filterEndpoint = Endpoint.CatalogueServices;
				break;
			case "languages":
				dispatch = languageDispatch;
				filterEndpoint = Endpoint.Languages;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(projectService.code)) {
			messages.push(t("fill_in_required_field", { field: t("serviceCode").toLowerCase() }));
		}
		if (!projectService.typeId) {
			messages.push(t("fill_in_required_field", { field: t("serviceType").toLowerCase() }));
		}
		if (!projectService.invoicingMomentId) {
			messages.push(t("fill_in_required_field", { field: t("invoicingMoment").toLowerCase() }));
		}
		if (!projectService.languages || projectService.languages.length <= 0) {
			messages.push(t("fill_in_required_field", { field: t("languages").toLowerCase() }));
		}
		if (!projectService.status) {
			messages.push(t("fill_in_required_field", { field: t("projectServiceStatus").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IProjectService): void {
		if (record && !close) {
			setProjectService(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IProjectService>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={projectService}
			endpoint={Endpoint.ProjectServices}
			extraUrlParams={{ projectId: props.projectId }}
			entityState={projectServiceState}
			entityType={t("projectService")}
			dispatch={projectServiceDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={projectService.code}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={!projectService.id ? firstCombobox : firstInput}
		>
			<div className="k-form">
				{!projectService.id && (
					<ManageableField
						fieldLabel={t("service")}
						recordId={catalogueService?.id}
						addScreen={{ screen: CatalogueServiceEditor, isAllowed: hasPermission(Permission.CatalogueServicesAdd) }}
						editScreen={{ screen: CatalogueServiceEditor, isAllowed: hasPermission(Permission.CatalogueServicesUpdate) }}
						setEntity={(service: ICatalogueService) => onCatalogueServiceChange(service)}
						readOnly={readonly}
					>
						<SearchBox
							name="catalogueService"
							entities={catalogueServiceState.entities}
							isLoading={catalogueServiceState.areEntitiesLoading}
							entityId={catalogueService?.id}
							entity={catalogueService}
							textField="code"
							onFilterChange={onFilterChange}
							onClose={(event: ComboBoxCloseEvent) => onCatalogueServiceChange(event.target.value)}
							onClear={() => setCatalogueService(undefined)}
							disabled={readonly}
							myRef={firstCombobox}
						/>
					</ManageableField>
				)}
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("serviceCode")} *</span>
							<Input name="code" ref={firstInput} value={projectService.code} onChange={onChange} required disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={`${t("serviceType")} *`}
							recordId={projectService.typeId}
							addScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesAdd) }}
							editScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesUpdate) }}
							setEntity={(type: IServiceType) => setChild(type, "type", "typeId")}
							readOnly={readonly}
						>
							<SearchBox
								name="typeId"
								entities={serviceTypeState.entities}
								isLoading={serviceTypeState.areEntitiesLoading}
								entityId={projectService.typeId}
								entity={projectService.type}
								required
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "type", "typeId")}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<ManageableMultiSelect
							fieldLabel={`${t("languages")} *`}
							addScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesAdd) }}
							editScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesUpdate) }}
							setEntity={addOrUpdateLanguage}
							name="languages"
							data={languageState.entities}
							textField="name"
							dataItemKey="id"
							value={map(projectService.languages, "language")}
							loading={languageState.areEntitiesLoading}
							onChange={onMultiSelectChange}
							filterable
							onFilterChange={onFilterChange}
							autoClose={false}
							disabled={readonly}
							readOnly={readonly}
							required
						/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("amount")}</span>
							<NumericTextBox name="amount" value={projectService.amount} onChange={onChange} min={0} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<div className="k-form-field">
							<span>{t("projectServiceStatus")} *</span>
							<EnumDropDownList
								name="status"
								value={projectService.status}
								onChange={onChange}
								enum={filter(ProjectServiceStatus, (status) => status !== ProjectServiceStatus.Invoiced || hasPermission(Permission.AllowSettingInvoicedStatus))}
								itemRender={renderProjectServiceStatus}
								required
								disabled={readonly}
							/>
						</div>
					</div>
				</div>
				<label className="k-form-field">
					<span>{t("shortDescription")}</span>
					<TextArea name="shortDescription" value={projectService.shortDescription} onChange={onChange} disabled={readonly} />
				</label>
				<label className="k-form-field">
					<span>{t("longDescription")}</span>
					<TextArea name="longDescription" value={projectService.longDescription} onChange={onChange} disabled={readonly} />
				</label>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("collectiveHours")}</span>
							<NumericTextBox name="collectiveHours" value={projectService.collectiveHours} onChange={onChange} format="n2" min={0} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("individualHours")}</span>
							<NumericTextBox name="individualHours" value={projectService.individualHours} onChange={onChange} format="n2" min={0} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("numberOfMonths")}</span>
							<NumericTextBox name="numberOfMonths" value={projectService.numberOfMonths} onChange={onChange} format="n0" min={0} disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={`${t("invoicingMoment")} *`}
							recordId={projectService.invoicingMomentId}
							addScreen={{ screen: InvoicingMomentEditor, isAllowed: hasPermission(Permission.InvoicingMomentsAdd) }}
							editScreen={{ screen: InvoicingMomentEditor, isAllowed: hasPermission(Permission.InvoicingMomentsUpdate) }}
							setEntity={(invoicingMoment: IInvoicingMoment) => setChild(invoicingMoment, "invoicingMoment", "invoicingMomentId")}
							readOnly={readonly}
						>
							<SearchBox
								name="invoicingMomentId"
								entities={invoicingMomentState.entities}
								isLoading={invoicingMomentState.areEntitiesLoading}
								entityId={projectService.invoicingMomentId}
								entity={projectService.invoicingMoment}
								required
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "invoicingMoment", "invoicingMomentId")}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("salesPrice")}</span>
							<NumericTextBox name="salesPrice" value={projectService.salesPrice} onChange={onChange} format="n2" disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("account")}</span>
							<Input name="account" value={projectService.account} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
			</div>
		</EntityEditor>
	);
};

export default ProjectServiceEditor;
