import React, { useState } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { customCell, dateCell, enumMultiSelectFilterCell, GridPanel, GridPanelColumn, IRoutedTabProps, translatedCell, YesNoSwitch } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { IApplicationState } from "../../../store";
import { Color, CommunicationLanguage, Permission, ProjectParticipantStatus } from "../../../utils/enums";
import { isGridCellProps } from "../../../utils/props";
import { IProjectParticipant, IUser } from "../../../utils/types/models";
import ProjectParticipantEditor from "../../editors/project/projectParticipant";
import { colorCell, colorCellClassName } from "../../global/colorCell";
import { addFieldInfo, getHoursColor } from "./services";

export function getProjectParticipantStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case ProjectParticipantStatus.NotStarted:
			return Color.Grey;
		case ProjectParticipantStatus.New:
			return Color.Blue;
		case ProjectParticipantStatus.Intake:
		case ProjectParticipantStatus.Session:
		case ProjectParticipantStatus.Stopped:
		case ProjectParticipantStatus.OnHold:
			return Color.Orange;
		case ProjectParticipantStatus.Completed:
		case ProjectParticipantStatus.Aftercare:
			return Color.Green;
	}
}

const Participants: React.FC<IRoutedTabProps> = (props: IRoutedTabProps) => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	return (
		<GridPanel
			listEndpoint={showAll ? Endpoint.GeneralProjectParticipantsList : Endpoint.MyProjectParticipantsList}
			userGridPanelConfiguration={{
				gridKey: props.reactKey,
				configurationEndpoint: Endpoint.GridPanelConfigurations,
				filterable: true
			}}
			endpoint={Endpoint.ProjectParticipants}
			editScreen={{
				screen: ProjectParticipantEditor,
				isAllowed: (projectParticipant: IProjectParticipant) =>
					hasPermission(Permission.ProjectParticipantsUpdate) && (hasPermission(Permission.ProjectParticipantsAdministrator) || projectParticipant.responsibleUserId === currentUser.id),
				extraProps: (projectParticipant: IProjectParticipant) => ({ projectId: projectParticipant.projectId, statusChange: true })
			}}
			delete={false}
			extraToolbarButtons={
				hasPermission(Permission.ProjectParticipantsAll) && [
					<span key="showAllLabel" style={{ marginRight: "10px" }}>
						{t("showAllParticipants")}
					</span>,
					<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
				]
			}
			sort={[{ field: "lastName", dir: "desc" }]}
		>
			<GridPanelColumn
				field="status"
				title={t("projectParticipantStatus")}
				cell={customCell(colorCell(true, translatedCell(), getProjectParticipantStatusColor), colorCellClassName)}
				filterCell={enumMultiSelectFilterCell(ProjectParticipantStatus)}
				isDefault
			/>
			<GridPanelColumn field="firstName" title={t("firstName")} isDefault={false} />
			<GridPanelColumn field="lastName" title={t("lastName")} isDefault={false} />
			<GridPanelColumn field="company" title={t("company")} isDefault={false} />
			<GridPanelColumn field="nationalRegistryNumber" title={t("nationalRegistryNumber")} isDefault={false} />
			<GridPanelColumn field="phoneNumber" title={t("phoneNumber")} isDefault={false} />
			<GridPanelColumn field="mobilePhoneNumber" title={t("mobilePhoneNumber")} isDefault={false} />
			<GridPanelColumn field="emailAddress" title={t("email")} isDefault={false} />
			<GridPanelColumn field="nationalityName" title={t("nationality")} isDefault={false} />
			<GridPanelColumn field="dateOfBirth" title={t("dateOfBirth")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault={false} />
			<GridPanelColumn field="genderName" title={t("gender")} cell={customCell(translatedCell())} isDefault={false} />
			<GridPanelColumn
				field="communicationLanguage"
				title={t("communicationLanguage")}
				filterCell={enumMultiSelectFilterCell(CommunicationLanguage)}
				cell={customCell(translatedCell())}
				isDefault={false}
			/>
			<GridPanelColumn field="highestDegreeName" title={t("highestDegree")} isDefault={false} />
			<GridPanelColumn field="street" title={t("street")} isDefault={false} />
			<GridPanelColumn field="number" title={t("number")} isDefault={false} />
			<GridPanelColumn field="postalCode" title={t("postalCode")} isDefault={false} />
			<GridPanelColumn field="municipality" title={t("municipality")} isDefault={false} />
			<GridPanelColumn field="province" title={t("province")} isDefault={false} />
			<GridPanelColumn field="country" title={t("country")} isDefault={false} />
			<GridPanelColumn field="employmentUnitEnrollDate" title={t("employmentUnitEnrollDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault={false} />
			<GridPanelColumn field="fullName" title={t("name")} isDefault />
			<GridPanelColumn field="projectTitle" title={t("project")} isDefault />
			<GridPanelColumn field="groupName" title={t("group")} isDefault />
			<GridPanelColumn field="individualHours" title={t("individualHours")} width="220px" cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} isDefault />
			<GridPanelColumn field="collectiveHours" title={t("collectiveHours")} width="220px" cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} isDefault />
			<GridPanelColumn field="responsibleUserFullName" title={t("projectParticipantResponsible")} isDefault />
		</GridPanel>
	);
};

export default Participants;
